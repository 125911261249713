<template>
    <section class="no-notification">
        <div class="container">
            <div class="no-notification-icon">
                <img src="@/assets/images/no-internet.webp" alt="no-internet" loading="lazy">
            </div>
            <div class="thm-heading">
                <h3 class="profit-color">Something went wrong</h3>
                <p>Make sure wifi or cellular data is turned on and <br> then try again </p>
            </div>
            <div class="thm-heading mt-lg-4 mt-2 data-btn">
                <a href="/" class="thm-but ">Try Again</a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "NoInternetConnection"
}
</script>