<template>
    <!-- login up Modal -->
    <div class="modal login-modal-box" id="login" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div v-if="siteSettings" class="modal-logo">
                        <img :src="siteSettings?.aws_url +
                        siteSettings?.storage_path?.domain_image +
                        siteSettings?.logo
                        " alt="logo.webp" loading="lazy" />
                    </div>
                    <button type="button" @click="refreshData()" ref="close_btn" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form class="registration-form">
                        <div class="login-tab-header">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                
                                <li v-if="siteSettings?.business_type == 2" class="nav-item" role="presentation">
                                    <button class="nav-link" :class="form.type == 1 ? 'active' : ''" @click="clickOnType(1)" id="mobile-tab" data-bs-toggle="tab"
                                        data-bs-target="#mobile" type="button" role="tab" aria-controls="mobile"
                                        aria-selected="false"><img src="@/assets/images/otp.webp" alt="otp" loading="lazy"> {{
                                            translatedLangData('mobile-number', 'Mobile number') }}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" :class="form.type == 2 ? 'active' : ''" id="user-tab" data-bs-toggle="tab"
                                        data-bs-target="#user" @click="clickOnType(2)" type="button" role="tab" aria-controls="user"
                                        aria-selected="true"><img src="@/assets/images/user-tab.webp" loading="lazy">{{translatedLangData('user-id', 'User ID') }}</button>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade" :class="form.type == 1 ? 'show active' : ''" id="mobile" role="tabpanel" aria-labelledby="mobile-tab" v-if="siteSettings?.business_type == 2">
                                <div class="form-group">
                                    <label class="khel-label">{{ translatedLangData('mobile-number', 'Mobile number') }}*</label>
                                    <div class="khel-country-code-wrapper">
                                            <div class="country-code-flag-top-wrapper" ref="dropdownWrapper">
                                                <div class="country-code-flag-top-sec" @click="clickCountry = !clickCountry" :class="timerRunning?'pointer-none':''">
                                                    <img :src="selectedCountryflag" alt="india-flag" class="india-flag" loading="lazy">
                                                    <span class="drp-btn">+{{form?.code}}</span>
                                                     
                                                </div>
                                                <ul class="country-code-flag-sec show-country-code" v-if="clickCountry">
                                                    <li @click="selectCountry(item)" v-for="(item,index) in siteSettings?.country_code_list" :key="index">
                                                        <img :src="item?.flag" alt="india-flag" class="india-flag" loading="lazy"> 
                                                        <span class="drp-btn">+ {{item?.phonecode}}</span>
                                                        
                                                    </li>
                                                </ul>
                                            </div> 

                                        <!-- </div> -->
                                        <div class="col-auto">
                                            <input type="text" v-model="form.mobileNo" maxlength="10" @input="validatePhone($event)" class="form-control"
                                                :placeholder="translatedLangData('enter-mobile-number','Enter Mobile Number')" />
                                        </div>
                                    </div>
                                    <span v-if="showValidationError && getId() == ''" class="text-danger">{{
                        translatedLangData('please-enter-mobile-number', 'Please enter mobile number')}}</span>
                                </div>


                            </div>
                            <div class="tab-pane fade" :class="siteSettings?.business_type != 2 || form.type == 2 ? 'active show' :''" id="user" role="tabpanel" aria-labelledby="user-tab">
                                <div class="form-group">
                                    <div class="col-12">
                                        <div class="col-auto">
                                            
                                            <label class="khel-label">{{translatedLangData('user-id', 'User ID') }}*</label>
                                            <input type="text" v-model="form.userId" @input="removeSpace($event)" class="form-control"
                                                :placeholder="translatedLangData('enter-user-id','Enter User ID')" />
                                        </div>
                                    </div>
                                    <span v-if="showValidationError && getId() == ''" class="text-danger">{{
                        translatedLangData('please-enter-user-id', 'Please enter user id') }}</span>
                                </div>

                            </div>
                            
                        </div>

                        <div class="row form-group">
                            <div class="col-12">
                                <label class="khel-label">{{translatedLangData('password','Password')}}*</label>
                                <div class="col-auto password-field">
                                    
                                    <input v-if="showPassword" type="text" @input="removeSpace($event)" v-model="form.password" class="form-control pass_log_id"
                                        :placeholder="translatedLangData('enter-pass', 'Enter Password')">
                                    <input v-else type="password" @input="removeSpace($event)" v-model="form.password" class="form-control pass_log_id"
                                        :placeholder="translatedLangData('enter-pass', 'Enter Password')">
                                        <div @click.prevent="showPassword =!showPassword">
                                            <span 
                                                v-if="showPassword"
                                                class="field_icon toggle-password">
                                                <i class="fas fa-eye"></i>
                                            </span>
                                            <span v-else
                                                class=" field_icon toggle-password">
                                                <i class="fas fa-eye-slash"></i>
                                            </span>
                                                
                                        </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <span v-if="showValidationError && form.password == ''" class="text-danger">{{
                                translatedLangData('please-enter-password', 'Please enter password') }}</span>
                            </div>
                        </div>
                        
                        <div class="row form-group">
                            <div class="col-12 khel-check-tc">
                                <label>
                                    <input type="checkbox" class="filled-in">
                                    <span >{{translatedLangData('i-accept-all','I accept all the')}} </span>
                                </label>
                                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#log-termsandconditions">{{translatedLangData('terms-and-conditions','Terms and conditions')}}</a>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12" v-if="siteSettings && siteSettings.business_type == 2">
                                <div class="col-auto forgot-pass">
                                    <a @click="redirectPage('/forget-password')">{{ translatedLangData('forgot-password', 'Forgot Password')}}?</a>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12">
                                <div class="col-auto">
                                    <a @click="signInMethodCall()" class="btn gradient-btn"
                                        :class="{ 'btn-loading': loading }">
                                        <span v-if="!loading">{{ translatedLangData('login', 'Login') }}</span>
                                        <span><b></b><b></b><b></b></span>
                                    </a>
                                    <a @click="demoUserLogin()" class="btn gradient-btn"
                                        :class="{ 'btn-loading': demoLoading }">
                                        <span v-if="!demoLoading">{{ translatedLangData('demo-sign-in', 'Demo') }}</span>
                                        <span><b></b><b></b><b></b></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                        <div v-if="siteSettings && siteSettings?.business_type == 2 && siteSettings?.social_link && siteSettings?.social_link?.whatsappno1" class="row form-group">
                            <div class="col-12">
                                <div class="col-auto">
                                    <h3 class="whats-with">{{ translatedLangData('or-continue-with', 'Get Your Ready-Made ID From ')}} <span class="whats-with-now"> {{
                                            translatedLangData('whatsapp','Whatsapp')}}</span></h3>
                                    <div class="button-whatsapp">
                                        <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1
                                                            " target="_blank"><button type="button"
                                                class="btn-whatsapp">
                                                <img src="@/assets/images/black-whatsapp.svg" alt="black-whatsapp" loading="lazy"/> 
                                                {{
                                                translatedLangData('whatsapp-now','Whatsapp Now')}}</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group" v-if="siteSettings && siteSettings?.business_type == 2">
                            <div class="col-12">
                                <div class="col-auto">
                                    <h3 class="whats-with"><span>{{ translatedLangData('or-login-with', 'or Login with')}}</span></h3>
                                    <div class="social-login-icon" v-if="siteSettings?.social_link">
                                        <a v-if="siteSettings?.social_link.facebook_link" :href="siteSettings?.social_link.facebook_link" target="_blank">
                                            <img src="@/assets/images/facebook-icon.webp" alt="facebook-icon" loading="lazy">
                                        </a>
                                        <a v-if="siteSettings?.social_link.google_link" :href="siteSettings?.social_link.google_link" target="_blank">
                                            <img src="@/assets/images/google-icon.webp" alt="google-icon" loading="lazy">
                                        </a>
                                        <a v-if="siteSettings?.social_link.instagram_link" :href="siteSettings?.social_link.instagram_link" target="_blank">
                                            <img src="@/assets/images/instagram-icon.webp" alt="instagram-icon" loading="lazy">
                                        </a>
                                        <a v-if="siteSettings?.social_link.telegram_link" :href="siteSettings?.social_link.telegram_link" target="_blank">
                                            <img src="@/assets/images/telegram-icon.webp" alt="telegram-icon" loading="lazy">
                                        </a>
                                    </div>

                                    <p class="whats-with-acc">
                                        <a @click="redirectPage('/sign-up')"> {{translatedLangData('create-new-account','Create New Account')}}</a></p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade khel-termsandconditions" id="log-termsandconditions" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"> Terms & Conditions Agreement </h4>
                </div>
                <div class="modal-body">
                    <p> All Exchange users are advised to read following 
                        <strong>Terms &amp; Conditions</strong>, 
                        Any user who will place bet in Exchange is understood to be agreeing and accepting following: 
                    </p>
                    <ul class="khel-terms-list">
                        <li> Any sort of <strong>heating</strong> bet , any sort of <strong>Matching (Passing of funds)</strong>, Court Siding <strong>(Ghaobaazi on commentary), Sharpening, Commission making</strong> is not allowed in Exchange, If any Exchange User is caught in any of such act then all the funds belonging that account would be seized and confiscated. No argument or claim in that context would be entertained and the decision made by Exchange management will stand as final authority. </li>
                        <li><strong>Fluke hunting/Seeking</strong> is prohibited in Exchange, All the fluke bets will be reversed. </li>
                        <li> In case of any technical issue or disruption of services, Exchange is neither liable nor responsible to pay any losses. </li>
                        <li> Betfair.com reserves the right to reverse, resettle or void any Market or bet in case of result change or mistake, etc , Exchange in that case will settle according to Betfair.com. </li>
                        <li> The decision made by Exchange in any matter is of utmost importance and final authority. </li>
                        <li><span class="new-terms">New</span> In case anyone is found using 2 different IDs and logging in from same IP his winning in both accounts will be cancelled. </li>
                    </ul>
                    <div class="terms-footer"><button type="button" data-bs-dismiss="modal" aria-label="Close"> I agree with all the terms &amp; conditions </button></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";
import api from "../services/api";
import * as apiName from "../services/urls";
import axios from "axios";
import { setHeaders } from "@/shared/services/headers";
import { mapGetters } from "vuex";
import { Modal } from "bootstrap";
import VideoModal from "@/shared/components/modal/VideoModal.vue";

export default {
    name: "Login",
    inject: ['translatedLangData'],
    computed: {
        ...mapGetters({
            siteSettings: "siteSettings",
            loggedUser: 'loggedUser'
        }),
    },
    data() {
        return {
            form: {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                passwordVisible: false,
                type: 1,
                captchaText: "",
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            demoLoading: false,
            modal: null,
            showPassword:false,
            choosedUserJourney: { link: null, title: null },
            showUserJourneyVideo: false,
            clickCountry: false,
            selectedCountryflag: 'https://flagcdn.com/in.svg',
            inputvalue:[
                    {
                            value: 100,
                            isSelected: true,
                    },
                    {
                            value: 200,
                            isSelected: false,
                    },
                    {
                            value: 500,
                            isSelected: false,
                    },
                    {
                            value: 1000,
                            isSelected: false,
                    },
                    {
                            value: 1200,
                            isSelected: false,
                    },
                    {
                            value: 1500,
                            isSelected: false,
                    },
                    {
                            value: 1800,
                            isSelected: false,
                    },
                    {
                            value: 2000,
                            isSelected: false,
                    },
                ],
        };
    },
    mounted() {
        if(this.$route.name == 'login' && !this.checkIsLogin()){
            setTimeout(()=> {
                let loginModal = new Modal('#login');
                loginModal.show();
            }, 500)
            this.$router.push({name: 'Home'})
        }
        else if(this.$route.name == 'login' && this.checkIsLogin()){
            this.$router.push('/')
        }
        if(localStorage.getItem('login_type') && this.siteSettings?.business_type == 2) {
            this.form.type = localStorage.getItem('login_type');
        }
        document.addEventListener('click', this.closeDropdownOnClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
    },
    methods: {
        selectCountry(item){
            this.form.code = item?.phonecode;
            this.selectedCountryflag = item?.flag;
            this.clickCountry = false;
        },
        closeDropdownOnClickOutside(event) {
            if (this.$refs.dropdownWrapper) {
                if (!this.$refs.dropdownWrapper.contains(event.target)) {
                    this.clickCountry = false;
                }
            }
        },
        refreshData() {
            (this.form = {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: "",
            }),
                (this.showValidationError = false);
        },
        demoUserLogin() {
            this.form.type = 1;
            this.form.userId = "demouser";
            this.form.password = "demopass";
            this.loginServiceCall(apiName.DEMO_LOGIN_API, "demo");
        },
        signInMethodCall() {
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "") {
                    this.showValidationError = true;
                } else {
                    this.loginServiceCall(apiName.LOGIN_API, "nondemo");
                }
            }
        },
        chooseUserJourney(userJourneLink, title) {
            if (userJourneLink) {
                userJourneLink = this.siteSettings?.aws_url + this.siteSettings?.storage_path.user_journey_path + userJourneLink;
            }
            this.choosedUserJourney = { link: userJourneLink, title: title };
            this.showUserJourneyVideo = true;
            this.$refs.show_user_journey_video.click();
        },
        async loginServiceCall(url, type) {
            let id = this.getId();
            if (type == "demo") {
                this.demoLoading = true;
            } else {
                this.loading = true;
            }
            let data = {
                userid: id.toString(),
                type: this.siteSettings?.business_type == 1 ? 2 : this.form.type,
                password: this.form.password,
                cncode: this.form.code,
            };

            api
                .post(url, data)
                .then((response) => {
                    if (type == "demo") {
                        this.demoLoading = false;
                    } else {
                        this.loading = false;
                    }
                    if (response) {
                        if (response.status == 200) {
                            if (response?.data?.status == 0) {
                                this.showErrorModalFunc(response.data.debug[0]);
                            } else {
                                this.showSuccessModalFunc(response.data.message);
                                let userData = response.data.data;
                                if(this.loggedUser!=userData.userid){
                                    this.$store.dispatch('setClickBetValue',this.inputvalue);
                                    this.$store.dispatch('setIsClickBetOn',false);
                                }
                                this.$cookies.set(AUTH_TOKEN, userData.token);
                                this.$store.dispatch("setToken", `Bearer ${userData.token}`);
                                window.authorization_token = `Bearer ${this.$cookies.get(
                                    AUTH_TOKEN
                                )}`;
                                //for add on all APIs
                                axios.defaults.headers.common[
                                    "Authorization"
                                ] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                                localStorage.setItem('login_type', data.type)
                                this.$store.dispatch('setSiteVersion', null);
                                this.$store.dispatch('setLoggedUser', userData.userid);
                                setHeaders();
                                this.setUserDataToStore(userData);
                                this.refreshData();
                                location.reload();
                                this.closeModal();
                            }
                        }
                    }
                })
                .catch((error) => {
                    if (type == "demo") {
                        this.demoLoading = false;
                    } else {
                        this.loading = false;
                    }
                    if (error) {
                        this.showErrorModalFunc(error[0]);
                    }
                });
        },
        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
            this.form.password = "";
            this.showValidationError = false
        },
        validatePhone(event){
            this.form.mobileNo = event.target.value.replace(/\D/g, '');
        },
        removeSpace(event){
            event.target.value = event.target.value.replace(/\s/g, '');
        },
        getId() {
            if (this.siteSettings?.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                return this.form.userId;
            }
        },
        showErrorModalFunc(message) {
            this.$emit("error-modal", message);
        },
        showSuccessModalFunc(message) {
            this.$emit("success-modal", message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        setUserDataToStore(userData) {
            let asciiCodes = null;
            for (let i = 0; i < userData.userid.length; i++) {
                asciiCodes += userData.userid.charCodeAt(i)
            }
            let user = {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                rid: userData.rid,
                userid: userData.userid,
                exposure: userData.expl,
                available_credits: userData.available_credits,
                id: userData.id,
                exp: userData.exp,
                notificationSubscribed:null,
                notificationUnsubscribed:null,
                activeWallet: userData.actw,
                asciiCodes: asciiCodes,
                cpi: userData.cpi,
            };
            this.$store.dispatch("setUser", user);
            this.$store.dispatch("setChips", userData.chips);
            this.$store.dispatch('setEventManage', userData.lock_settings);
        },
        redirectPage(pageName){
            this.refreshData();
            this.closeModal();
            this.$router.push({path:pageName})
        }
    },
    components: { VideoModal },
};
</script>

<style scoped>
div.mm-dropdown ul li {
    display: flex !important;
    padding-left: 10px;
}
</style>