<template>
    <div class="container">
        <div class="custumer-support-btn khel-download-btn-sec">
            <button class="btn support-btn khel-download-btn" data-bs-target="#install-apk-model" data-bs-toggle="modal"><img src="@/assets/images/download-icon.svg" alt="download-icon" loading="lazy"> 
                <span class="livebtn">{{translatedLangData('download-application','Download App')}}
                </span>
            </button>
        </div>

        <div class="custumer-support-btn" v-if="checkIsLogin() && siteSettings?.business_type == 2">
            <button class="btn support-btn khel-whatsapp-id-btn" target="_blank" @click="getParentWhatsappNumber" 
                data-bs-toggle="modal" data-bs-target="#whatsup-depositModal"><img src="@/assets/images/customer-service.webp" alt="customer-service" loading="lazy" style="filter:invert(1) brightness(2.5)"> 
                <span class="livebtn">
                    {{translatedLangData('24-7-support','24/7 Customer Support')}}
                </span>
            </button>
        </div>
        <div class="custumer-support-btn" v-else-if="siteSettings?.business_type == 2">
            <a :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank" class="btn support-btn khel-whatsapp-id-btn"  v-if="siteSettings?.social_link?.whatsappno1" >
                <img src="@/assets/images/whatsappImages.svg" loading="lazy" alt="whatsappImages"> 
                <span class="livebtn">
                    {{translatedLangData('get-an-id-instantly-on-whatsapp','Get Instant ID on WhatsApp')}}
                </span>
            </a>
        </div>
        <div v-if="siteSettings?.user_journey" class="tutorials-section">
            <h3>{{ translatedLangData('tutorials', 'tutorials') }}</h3>
            <div class="tutorials-btn-groups">
                <div class="custumer-support-btn">
                    <button @click="chooseUserJourney(siteSettings?.user_journey?.deposit_video_e, 'How to Deposit?')" class="btn support-btn">
                        <i class="fa fa-video-camera"></i>
                        <span class="livebtn">{{ translatedLangData('how-to-deposit', 'How to Deposit?') }}</span>
                    </button>
                </div>
                <div class="custumer-support-btn">
                    <button @click="chooseUserJourney(siteSettings?.user_journey?.register_video, 'How to Signup?')"
                        class="btn support-btn">
                        <i class="fa fa-video-camera"></i>
                        <span class="livebtn">{{ translatedLangData('how-to-signup', 'How to Signup?') }}</span>
                    </button>
                </div>
                <div class="custumer-support-btn">
                    <button @click="chooseUserJourney(siteSettings?.user_journey?.login_video, 'How to Login?')"
                        class="btn support-btn">
                        <i class="fa fa-video-camera"></i>
                        <span class="livebtn">{{ translatedLangData('how-to-login', 'How to Login?') }}</span>
                    </button>
                </div>
                <div class="custumer-support-btn">
                    <button @click="chooseUserJourney(siteSettings?.user_journey?.play_video, 'How to Play?')"
                        class="btn support-btn">
                        <i class="fa fa-video-camera"></i>
                        <span class="livebtn">{{ translatedLangData('how-to-play', 'How to Play?') }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="footer-bets-wrapper">
            <div v-if="siteSettings && siteSettings?.logo" class="footer-logo">
                <a><img :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo" alt="footer-logo"></a>
            </div>
            <div v-if="siteSettings?.apk" class="footer-logo">
                <a :href="siteSettings?.aws_url + siteSettings?.storage_path?.apk_path + siteSettings?.apk"
                    :download="siteSettings?.apk"><img src="@/assets/images/android-apk.webp" alt="android-apk" loading="lazy"></a>
            </div>
        </div>
        <Copyrights />
        <div class="footer-menu">
            <ul>
                <li><a class="khel-footer-menu-header">{{translatedLangData('primary','Primary')}}</a></li>
                <li><router-link :to="{name: 'exchange-games'}">{{translatedLangData('all-sports','All Sports')}}</router-link></li>
                <li><router-link :to="{ name: 'inplay-games' }">{{translatedLangData('inplay-footer-label','In-Play')}}</router-link></li>
                <li><router-link :to="{ name: 'all-games' }">{{translatedLangData('all-casinos','All Casinos')}}</router-link></li>
                <li><router-link :to="{ name: 'static', params: { type: types().BLOGS } }">{{translatedLangData('blogs','Blogs')}}</router-link></li>
            </ul>
            <ul>
                <li><a class="khel-footer-menu-header">{{translatedLangData('setting','Settings')}}</a></li>
                <li><router-link :to="{ name: 'static', params: { type: types().COOKIE_POLICY } }">{{translatedLangData('cookie-policy','Cookie Policy')}}</router-link></li>
                <li><router-link :to="{ name: 'static', params: { type: types().RESPONSIBLE_GAMING } }">{{translatedLangData('responsible-gambling','Responsible Gambling')}}</router-link></li>
                <li><router-link :to="{ name: 'static', params: { type: types().TERM_CONDITIONS } }">{{translatedLangData('terms-conditions','Terms & Conditions')}}</router-link></li>
            </ul>
        </div>
        <div v-if="siteSettings && siteSettings?.social_link && siteSettings?.business_type == 2" class="footer-social">
            <a v-if="siteSettings?.social_link?.facebook_link" :href="siteSettings?.social_link?.facebook_link"
                target="_blank"><img src="@/assets/images/Faceook.webp" alt="Faceook"  loading="lazy"></a>
            <a v-if="siteSettings?.social_link?.youtube_link" target="_blank" :href="siteSettings?.social_link?.youtube_link"><img src="@/assets/images/Youtube.webp" alt="Youtube"  loading="lazy"></a>
            <a v-if="siteSettings?.social_link?.instagram_link" :href="siteSettings?.social_link?.instagram_link"
                target="_blank"><img src="@/assets/images/Instagram.webp" alt="Instagram"  loading="lazy"></a>
            <a v-if="siteSettings?.social_link?.telegram_link" :href="siteSettings?.social_link?.telegram_link"
                target="_blank"><img src="@/assets/images/Telegram.webp" alt="Instagram"  loading="lazy"></a>
        </div>
    </div>
    <div class="modal fade game-play-modal khel-tutorial-modal" ref="footerModal" v-if="showUserJourneyVideo" id="show_user_journey_video" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog ">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="game-header-content">
                        <div class="game-bak-close-block">
                            <strong class="video-title">{{ choosedUserJourney ? choosedUserJourney.title : null }}</strong>
                        </div>
                        <div class="close-game-btn">
                            <a href="javascript:void(0);" data-bs-dismiss="modal" @click.prevent="showUserJourneyVideo = false"><img src="@/assets/images/red-close-btn.webp"
                                    alt="red-close-btn"  loading="lazy"></a>
                        </div>
                    </div>
                </div>
                <VideoModal v-if="showUserJourneyVideo" :link="choosedUserJourney ? choosedUserJourney.link : null" />
            </div>
        </div>
    </div>

</template>

<script>
import * as types from '@/modules/static/utils/constants';
import Copyrights from './Copyrights.vue';
import VideoModal from '../modal/VideoModal.vue';
import { getWalletGetwayServiceCall } from "@/shared/services/wallet-service";
import { Modal } from 'bootstrap';

export default {
    name: 'Contacts',
    inject: ['translatedLangData'],
    data() {
        return {
            choosedUserJourney: { link: null, title: null },
            showUserJourneyVideo: false,
            whatsappNumbers:[]
        }
    },
    components: {
        Copyrights,
        VideoModal
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    methods: {
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        types() {
            return types;
        },
        chooseUserJourney(userJourneLink, title) {
            if (userJourneLink) {
                userJourneLink = this.siteSettings?.aws_url + this.siteSettings?.storage_path.user_journey_path + userJourneLink;
            }
            this.choosedUserJourney = { link: userJourneLink, title: title };
            this.showUserJourneyVideo = true;
            setTimeout(()=>{
                let userJourneyModal = new Modal(document.getElementById('show_user_journey_video'))
                userJourneyModal.show();
            },500)
        },
        clickOnWalletDeposit() {
            this.$router.push({ path: '/wallet-deposit', query: { walletData: null } })
        },
        getWalletGateway() {
            getWalletGetwayServiceCall();
        },
        async getParentWhatsappNumber() {
            if (this.checkIsLogin()) {
                this.loading = true;
                try {
                    const cpiExists = this.$store.getters.stateUser?.cpi;
                    const supportNumbers = this.siteSettings.support_numbers || [];
                    if (!cpiExists) {
                        return;
                    }
                    const now = new Date();
                    const totalMinutes = now.getHours() * 60 + now.getMinutes();
                    this.whatsappNumbers = supportNumbers
                        .filter(entry => entry.id === cpiExists)
                        .flatMap(entry => entry.categories.map(category => {
                            const whatsappNumbers = category.value.split(',');
                            const selectedNumberIndex = totalMinutes % whatsappNumbers.length;
                            return {
                                category_name: category.name,
                                whatsapp_number: whatsappNumbers[selectedNumberIndex]
                            };
                        }));
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.showErrorModalFunc(error[0]);
                }
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}
</script>

<style scoped>
.brand-pay{
    cursor: pointer;
}
.video-title{
    color: var(--primary-color);
}
.game-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>