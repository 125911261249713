
import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const MaintenanceComponent = () => import("@/shared/components/maintenance/Maintainence.vue");

import WalletRoutes from "@/modules/wallet";
import BetsRoutes from "@/modules/bets";
import GamesRoutes from "@/modules/games";
import MyMarketRoutes from "@/modules/my-market";
import SportsRoutes from "@/modules/sports";
import VirtualSportRoutes from "@/modules/virtual-sport";
import SportBookRoutes from "@/modules/sports-book";
import MatkaRoutes from "@/modules/matka";
import NotificationsRoutes from "@/modules/notification";
import RecommendedRoutes from "@/modules/recommended";
import CricketFightRoutes from "@/modules/cricket-fight";
import RaceRoutes from "@/modules/race";
import BonusRoutes from "@/modules/bonus";
import ReferEarnRoutes from "@/modules/refer-earn";
import StaticRoutes from "@/modules/static";
import AuthorizationRoutes from "@/modules/authorization";
import AccountRoutes from "@/modules/account";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: MaintenanceComponent
  },
  AuthorizationRoutes,
  WalletRoutes,
  BetsRoutes,
  GamesRoutes,
  MyMarketRoutes,
  SportsRoutes,
  VirtualSportRoutes,
  SportBookRoutes,
  MatkaRoutes,
  NotificationsRoutes,
  RecommendedRoutes,
  CricketFightRoutes,
  RaceRoutes,
  BonusRoutes,
  ReferEarnRoutes,
  StaticRoutes,
  AccountRoutes
];


export default createRouter({
  history: createWebHistory(),
  routes
});

