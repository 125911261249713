<template>
<div class="modal fade modal-footer-sticky openbetModal open-bets-modal-list" id="openbetModal" tabindex="-1" aria-labelledby="exampleModalLabel" v-if="filteredBets"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
         <div class="modal-dialog onload-modal  exit-clickbet">
            <div class="modal-content">
               <div class="modal-header">
                  <div class="betsslip-header-title"  v-if="this.$route.name == 'sports-event-detail'">
                     <div class="new-disp-flex">{{translatedLangData('this-event-bets','This Event Bets')}}</div>
                     <label><input class="form-check-input" v-model="averageOdds" type="checkbox" value=""
                     id="flexCheckDefault">{{ translatedLangData('average-odds', 'Average Odds') }}</label>
                  </div>
                  <button type="button" class="btn-close" @click="closeFooterModal()" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body ">
                  <div class="openbets-section" >
                     <BetsByMatch v-if="this.$route.name == 'sports-event-detail'" :averageOdds="averageOdds" :filteredBets="filteredBets" />
                     <MyMarket />
                  </div>
               </div>
            </div>
         </div>
    </div>

</template>
<script>

import NoDataFound from '@/shared/components/no-data/NoDataFound.vue';
import BetsByMatch from '@/modules/sports/components/BetsByMatch.vue';
import MyMarket from '@/modules/my-market/views/MyMarket.vue';

export default {
   name: 'openBetsFooter',
   inject: ['translatedLangData'],
   data() {
      return {
         averageOdds: false,
      };
   },
   // props:['showMybets'],
   computed:{
      openBets() {
         return this.$store.getters.hasOpenbets;
      },
      filteredBets(){
         if(this.averageOdds){
            let groupedBets = {
               bookmakers: { back: [], lay: [] },
               fancies: { back: [], lay: [] },
               main_market: { back: [], lay: [] }
            };
            this.openBets.forEach(bet => {                     
               ['bookmakers', 'fancies', 'main_market'].forEach(category => {
                  if (bet[category] && bet[category].length > 0) {
                     bet[category].forEach(item => {
                        if (item.selection_type === 'back') {
                              groupedBets[category].back.push(item);
                        } else if (item.selection_type === 'lay') {
                              groupedBets[category].lay.push(item);
                        }
                     });
                  }
               });
            });
            return groupedBets;
         }else{
            let openbets = this.openBets?.flatMap(bet => [...bet.main_market, ...bet.bookmakers, ...bet.fancies]);
            return openbets;
         }
      }
   },
   mounted() {
   },
   methods:{
      closeFooterModal(){
         this.$store.dispatch('setShowMybets', true);
      }
   },
   components: { NoDataFound, BetsByMatch,MyMarket }
}

</script>

<style scoped>
.back-slip {
   background-color: #43ceed;
   /* border: 3px solid #15739B; */
   border-radius: 2px;
}

.lay-slip {
   background-color: #f796ff;
   /* border: 3px solid #E56275; */
   border-radius: 2px;
}
.text-white{
   color: white !important;
}
.profit{
   color: green !important;
}
.loss{
   color: red !important;
}
</style>