import App from "./App.vue";
import router from "./router"
import store from "./store";
import VueCookies from 'vue3-cookies';
import { createApp } from 'vue'
import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";

import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import VueAwesomePaginate from "vue-awesome-paginate";
// import the necessary css file
import "vue-awesome-paginate/dist/style.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "./assets/css/style1.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import './registerServiceWorker'

import { library,dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons' ;
import Clipboard from "v-clipboard";
import casinoGamesHandler from "./shared/services/casinoGamesHandler";
import { Modal } from "bootstrap";
dom.watch();
library.add(fas);
window.addEventListener('popstate', (event) => {
    if(document.querySelector('.modal.show')) {
        window.location.reload();
    }
});
router.beforeEach((to, from,next) => {
    window.scrollTo({
        top:0,
    })
    if(document.querySelector('.modal.show')) {
        next(false);
        return;
    };
    
    const underMaintenance = store.getters.underMaintenance;
    const isMaintenancePage = to.name === 'Maintenance';
    const isAuthenticated = window.store?.getters.isAuthenticated;
    const lastLoginAt = window.store?.getters?.stateUser?.last_login_at;
    if (underMaintenance) {
        if (!isMaintenancePage) {
            next({ name: 'Maintenance' });
        } else {
            next();
        }
    } else {
        if (isMaintenancePage) {
            next({ name: 'Home' });
        } else if (isAuthenticated && !lastLoginAt && to.name !== 'change-password') {
            next({ name: 'change-password' });
        } else {
            next();
        }
    }

    if(window.store?.getters.isAuthenticated && !window.store?.getters?.stateUser?.last_login_at && to.name != 'change-password'){
        window.router.push({ name: 'change-password'});
    }
    // window.store.dispatch('setSearchText','');
})

const app = createApp(App);
app.config.globalProperties.$loadCasinoGame = casinoGamesHandler;
app.use(store);
app.use(router);
app.use(VueCookies);
app.component("v-select", vSelect);
app.component("font-awesome-icon", FontAwesomeIcon)
app.use(VueAwesomePaginate);
app.use(Clipboard);
app.mount("#app");
//app.config.productionTip = false;


// define global properties
app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";