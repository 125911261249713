<template>
    <div class="bonus-info">
        <div class="modal fade match-reminder-modal" data-bs-backdrop="static" data-bs-keyboard="false" id="informationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true"
            role="dialog">
            <div class="modal-dialog modal-md modal-dialog modal-md -centered information-modal-wrapper">
                <div class="modal-content">
                    <div class="modal-header">
                            <h4>{{translatedLangData('bonus-information','Bonus information')}}</h4>
                        
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="back-modal">
                            <div class="mene-details information-modal-sec">
                                <div class="menu-details-list">
                                    <ul>
                                        <li v-for="(rule, r_index, rule_num) in siteRuleData" :key="r_index">
                                            <div class="menu-details-heading" v-if="r_index != 'General'">
                                                <h4>{{ rule_num }}. {{ r_index }}</h4>
                                                <span v-html="rule"></span>
                                            </div>
                                        </li>
                                        <li v-if="!siteRuleData || !Object.keys(siteRuleData).length">
                                            <span class="sm-bd-font">{{translatedLangData('no-rules-available','No rules available')}}.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WelcomeBonus',
    props:['siteRuleData'],
    inject:['translatedLangData']
}
</script>