<template>

    <div class="modal fade menu-category-modal" id="offcanvasExample" tabindex="-1" aria-labelledby="menuItemLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="header-top">
                        <div class="back-button d-flex justify-content-center align-content-center" @click="$emit('handleFooterBehavior')"
                            data-bs-dismiss="modal" ref="close_button" aria-label="Close">
                            <img src="@/assets/images/back.webp" alt="back" loading="lazy">
                        </div>
                        <div class="innerdiv">
                            <div class="col-cls">
                                <p class="b-title">{{ translatedLangData('balance', 'Balance') }}</p>
                                <p class="b-balance">{{ (userData?.balance - (lockedEarnedData?.earnBonus || 0)).toFixed(2) }}</p>
                            </div>
                            <div class="col-cls">
                                <p class="b-title">{{ translatedLangData('liability', 'Liability') }}</p>
                                <p class="b-lib">{{ (parseFloat(userData?.exposure) || 0).toFixed(2) }}</p>
                            </div>
                            <a class="btn thm-btn" v-if="siteSettings?.business_type == 2" @click="getWalletGateway()">
                                {{ translatedLangData('deposit', 'Deposit') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="user-profile-wrapper">
                        <div class="user-login-title">
                            <div class="bonus-sec" v-if="lockedEarnedData && siteSettings?.business_type == 2">
                                <div class="credits-chackn-box">
                                    <div class="menu-details-heading">
                                        <h4>
                                            <a @click.prevent="getRules(), openBonusModal('informationModal')">
                                                {{ translatedLangData('bonus-information', 'Bonus Information') }}
                                                <span class="information-icon">
                                                    <img src="@/assets/images/information-button.webp" alt="information-button" loading="lazy">
                                                </span>
                                            </a>
                                        </h4>
                                    </div>
                                </div>
                                <div class="credits-list">
                                    <div class="credits-list-con">
                                        <h5>{{ translatedLangData('earn-bonus', 'Earned Bonus') }}</h5>
                                        <h6>{{ lockedEarnedData?.earnBonus }}</h6>
                                    </div>
                                    <div class="credits-list-con">
                                        <a @click.prevent="getBonusLogs(), openBonusModal('locked-bonus-modal')">
                                            <h5>{{ translatedLangData('locked-bonus', 'Locked Bonus') }}
                                                <span class="information-icon">
                                                    <img src="@/assets/images/information-button.webp" alt="information-button" loading="lazy">
                                                </span>
                                            </h5>
                                            <h6>{{ lockedEarnedData?.lockedBonus }}</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="credits-chackn-box border-none">
                                    <div class="menu-details-heading">
                                        <h4>
                                            <a>
                                                {{ translatedLangData('welcome-bonus', 'Welcome Bonus') }}

                                            </a>
                                        </h4>
                                    </div>
                                    <div class="menu-heading-con welcome-bonus">
                                        <strong>{{ lockedEarnedData?.welcomeBonus }}</strong>
                                    </div>
                                </div>
                                <div class="credits-chackn-box">
                                    <div class="menu-details-heading">
                                        <h4>{{ translatedLangData('play-with-bonus', 'Play with Bonus') }}</h4>
                                    </div>
                                    <div class="menu-heading-con">
                                        <div class="form-check form-switch m-0 p-0">
                                            <input class="form-check-input" @change="updateWalletType" v-model="getWalletType" type="checkbox" id="settingCheckDefaults">
                                        </div>
                                    </div>
                                </div>

                                <div class="credits-chackn-box">
                                    <router-link :to="{name:'refer-earn'}" @click="closeModal()" class="khel-refer-btn">
                                        <div class="menu-details-heading">
                                            <h4>{{translatedLangData('refer-earn','Refer & Earn')}}
                                                <img src="@/assets/images/gift.png" alt="gift" loading="lazy">
                                            </h4>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="body-menu-category">
                        <ul>
                            <li><router-link @click="closeModal()" :to="{name: 'Home'}"><span><img
                                            src="@/assets/images/home-menu.webp" loading="lazy" alt="home-menu"></span><p>{{
                                    translatedLangData('home', 'Home') }}</p></router-link>
                            </li>
                            <li><router-link @click="closeModal()" :to="{name: 'inplay-games'}"><span><img
                                            src="@/assets/images/inplay.webp" loading="lazy" alt="inplay"></span><p>{{
                                    translatedLangData('inplay', 'Inplay') }}</p></router-link></li>
                            <li><router-link @click="closeModal()" :to="{name: 'exchange-games'}"><span>
                                <img src="@/assets/images/exchange.webp" loading="lazy" alt="exchange"></span><p>{{
                                    translatedLangData('exchange', 'Exchange') }}</p></router-link></li>
                            <li v-if="checkEventManageforSingleType('sports-book')"><router-link @click="closeModal()" :to="{name: 'sports-book'}"><span><img src="@/assets/images/sportsBook.webp" loading="lazy" alt="sportsBook"></span><p>{{
                                    translatedLangData('sports-book', 'Sportsbook') }}</p></router-link></li>
                        </ul>
                    </div>
                    <!-- Casino -->
                    <div class="body-menu-category border-outer" v-if="gamesData?.sections?.length > 0">
                        <h4>{{ translatedLangData('casinos', 'Casinos') }}</h4>
                        <ul>
                            <li v-for="(item, cName) in casinoGames" :key="cName">
                                <a href="javascript:void(0)" @click="navigateToGames(cName)">
                                    <span>
                                        <img :src="item?.img" loading="lazy" :alt="item.name" >
                                    </span>
                                    <p>{{ item.name }}</p>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="navigateToGames('all-games')">
                                <span>
                                    <img src="@/assets/images/AllCasinos.webp" loading="lazy" alt="AllCasinos">
                                </span>
                                <p>{{translatedLangData('all-casinos','All Casino')}}</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- Multiplayer -->
                    <div class="body-menu-category border-outer" v-if="sectionWiseGamesList?.multiplayer_games?.length>0">
                        <h4>{{ translatedLangData('multiplayer', 'Multiplayer') }}</h4>
                        <ul>
                            <li v-for="(item, index) in (sectionWiseGamesList?.multiplayer_games?.length > 3 ? sectionWiseGamesList?.multiplayer_games?.splice(0,3) : sectionWiseGamesList?.multiplayer_games)"
                                :key="index"><a @click="clickOnGamestButton(item)"><span><img :src="item?.image" @error="setDefaultImg"
                                            :alt="item.game_name" loading="lazy"></span><p>{{ item.game_name }}</p></a></li>
                            <li><router-link @click="closeModal()"
                                    :to="{ name: 'games-category', params: { type: 'multiplayer_games' } }"><span><img
                                            src="@/assets/images/all_multiplayer.webp" loading="lazy"></span><p>{{
                                    translatedLangData('all', 'All') }}&nbsp;{{ translatedLangData('multiplayer',
                                    'Multiplayer') }}</p></router-link></li>
                        </ul>
                    </div>
                    <!-- Sports -->
                    <div class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('sports', 'Sports') }}</h4>
                        <ul>
                            <li v-for="(sport, n_index) in non_custom_sports" :key="n_index"
                                v-show="checkInEventMange(sport.slug)">
                                <router-link @click="closeModal()"
                                    :to="'/sports/' + sport?.name + '/' + sport?.id"><span>
                                        <img loading="lazy" :src="require(`@/assets/images/non_custom_icons/${getIcon(sport.id)}`)" :alt="sport.name">
                                    </span><p>{{ translatedLangData(sport.slug,sport?.name) }}</p></router-link>
                            </li>
                            <li v-for="(sport, n_index) in custom_sports" :key="n_index"
                                v-show="checkInEventMange(sport.slug)">
                                <router-link @click="closeModal()"
                                    :to="'/sports/' + sport?.slug + '/' + sport?.id"><span>
                                        <img loading="lazy" :src="require(`@/assets/images/non_custom_icons/${getIcon(sport.id)}`)" alt="">
                                    </span><p>{{ sport?.name }}</p></router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Esports -->
                    <div v-if="sectionWiseGamesList?.esports?.length>0"
                        class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('esports', 'ESports') }}</h4>
                        <ul>
                            <li v-for="(item, index) in (sectionWiseGamesList?.esports?.length > 3 ? sectionWiseGamesList?.esports?.splice(0,3) : sectionWiseGamesList?.esports)"
                                :key="index"><a @click="clickOnGamestButton(item)"><span><img :src="item?.image"
                                            :alt="item.game_name" loading="lazy"></span><p>{{ item.game_name }}</p></a>
                            </li>
                            <li><router-link @click="closeModal()"
                                    :to="{ name: 'games-category', params: { type: 'esports' } }"><span><img
                                            src="@/assets/images/all_esportsnew.webp" alt="all_esportsnew" loading="lazy"></span><p>{{
                                    translatedLangData('all', 'All') }}&nbsp;{{ translatedLangData('esports', 'ESports')
                                    }}</p></router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Popular Sports -->
                    <div class="body-menu-category border-outer" v-if="sectionWiseGamesList?.popular_games?.length>0">
                        <h4>{{ translatedLangData('popular-games', 'Popular Games') }}</h4>
                        <ul>
                            <li v-for="(item, index) in (sectionWiseGamesList?.popular_games?.length > 4 ? sectionWiseGamesList?.popular_games?.splice(0,4) : sectionWiseGamesList?.popular_games)"
                                :key="index"><a @click="clickOnGamestButton(item)"><span><img :src="item?.image" @error="setDefaultImg"
                                            :alt="item.game_name" loading="lazy"></span><p>{{ item.game_name }}</p></a>
                            </li>
                        </ul>
                    </div>
                    <!-- Providers -->
                    <div v-if="sectionWiseGamesList?.providers?.length>0"
                        class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('provider', 'Providers') }}</h4>
                        <ul>
                            <li v-for="(item, index) in (sectionWiseGamesList?.providers?.length > 3 ? sectionWiseGamesList?.providers?.splice(0,3) : sectionWiseGamesList?.providers)"
                                :key="index"><a @click="clickOnGamestButton(item)"><span><img :src="item?.image" @error="setDefaultImg"
                                            :alt="item.game_name" loading="lazy"></span><p>{{ item.game_name }}</p></a></li>
                            <li><router-link @click="closeModal()"
                                :to="{ name: 'games-category', params: { type: 'providers' } }"><span><img
                                src="@/assets/images/allproviders.webp" alt="allproviders" loading="lazy"></span><p>
                                {{ translatedLangData('all-providers','All Providers') }}</p></router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="body-menu-category border-outer">
                        <h4>{{ translatedLangData('accessibility', 'Accessibility') }}</h4>
                        <ul>
                            <li><router-link @click="closeModal()" :to="{name : 'my-market'}"><span><img
                                            src="@/assets/images/mymarkets.webp" alt="mymarkets" loading="lazy"></span><p>{{
                                    translatedLangData('my-market','My Markets') }}</p></router-link></li>
                            <li v-if="siteSettings?.business_type == 2"><a @click="closeModal(),getWalletGateway()"><span><img
                                            src="@/assets/images/quickWithdraw.webp" loading="lazy"></span><p>{{
                                    translatedLangData('withdraw', 'Withdraw') }}</p></a></li>
                            <li v-if="siteSettings &&
                                    siteSettings?.social_link &&
                                    siteSettings?.social_link?.whatsappno1 && siteSettings?.business_type == 2"><a
                                    :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1"
                                    target="blank"><span><img src="@/assets/images/Whatsapp.webp" loading="lazy"></span><p>{{
                                    translatedLangData('support', 'Support') }}</p></a></li>
                            <li><router-link @click="closeModal()" :to="{name: 'settings'}"><span><img src="@/assets/images/setting.webp" alt="setting" loading="lazy" ></span><p>{{
                                    translatedLangData('setting', 'Settings') }}</p></router-link>
                            </li>
                        </ul>
                        <a @click="closeModal()" href="#install-apk-model" data-bs-toggle="modal" class="btn gradient-btn w-100 khel-download-btn mt-3"><img src="@/assets/images/download-icon.svg" alt="download-icon" loading="lazy"> {{translatedLangData('download-app','Download App')}}</a>
                        <a @click="closeModal(),logoutServiceCall()" class="btn gradient-btn w-100 mt-3 khel-menu-logout">{{
                                    translatedLangData('logout', 'Logout') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <StakeModal @success-modal="showSuccessModalFunc"
        @error-modal="showErrorModalFunc" />


    <ClickBetModal @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />

    <CreditReference />
    <LogoutModal @close="closeModal()" @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />

    <LockedBonus :bonusLogs='bonusLogs' @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />
    <WelcomBonus :siteRuleData='siteRuleData' @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />


    <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#login">
    </div>

</template>
<script>
import Footer from "@/shared/components/footer/Footer.vue";
import BonusInformation from './BonusInformation.vue';
import StakeModal from './StakeModal.vue';
import CreditReference from './CreditReference.vue';
import RulesModal from './RulesModal.vue';
import LogoutModal from './LogoutModal.vue'
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import ClickBetModal from './ClickBetModal.vue';
import { kFormatter } from '@/shared/utils/formatter';
import api from '../services/api';
import * as apiName from '../services/urls';
import { Modal } from 'bootstrap';
import { mapGetters } from 'vuex';
import * as types from '@/modules/bets/utils/constants'
import LockedBonus from '@/shared/components/header/LockedBonus.vue';
import WelcomBonus from '@/modules/bonus/components/WelcomBonus.vue';

export default {
    name: "Account",
    inject: ['translatedLangData'],
    data() {
        return {
            siteRuleModal: null,
            userData: null,
            themeSelection: false,
            clickBetCheck: false,
            themeName: '',
            arrayOfCountries: null,
            isMountedComponent: false,
            sectionsList: null,
            lockedEarnedData: null,
            bonusLogs: null,
            siteRuleData: null,
            getWalletType: false,
            lock_settings:null,
            casinoGames:{
                "live_casino" : {img: require('@/assets/images/live_casino.webp'), name: 'Live Casino'},
                "table_games" : {img: require('@/assets/images/table_games.webp'), name: 'Table Games'},
                "indian_casino" : {img: require('@/assets/images/indian_casino.webp'), name: 'Indian Casino'},
                "slot_games" : {img: require('@/assets/images/slot_games.webp'), name: 'Slot Games'},
                "lottery_games" : {img: require('@/assets/images/lottery_games.webp'), name: 'Lottery Games'},
                "scratch_games" : {img: require('@/assets/images/scratch_games.webp'), name: 'Scratch Games'},
                "shooting_games" : {img: require('@/assets/images/shotting_games.webp'), name: 'Shooting Games'},
                "crash_games" : {img: require('@/assets/images/crash_games.webp'), name: 'Crash Games'},
                "lightning" : {img: require('@/assets/images/lightning_roulette.webp'), name: 'Lightning'},
                "black_jack" : {img: require('@/assets/images/blackjack.webp'), name: 'Black Jack'},
            },
            sectionWiseGamesList: {
                multiplayer_games: [],
                esports: [],
                popular_games: [],
                providers: []
            }
        }
    },
    computed: {
        ...mapGetters({
            getNotification: 'getNotification',siteSettings:'siteSettings'
        }),
        types() {
            return types;
        },
        non_custom_sports(){
            return this.$store.getters.sportsData?.non_custom
        },
        gamesData(){
            return JSON.parse(JSON.stringify(this.$store.getters.gamesData))
        }
    },
    mounted() {
        this.lock_settings = this.$store?.getters?.lock_settings;
        if (this.getClickBetValue()) {
            this.clickBetCheck = true
        }
        this.sectionsList = this.$store?.getters?.gamesData.sections;
        this.isMountedComponent = true;
        this.userData = this.$store?.getters?.stateUser;
        window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
        if (localStorage.getItem('default_class')) {
            this.themeName = localStorage.getItem('default_class');
        }
        setHeaders();
        this.arrayOfCountries = [
            {
                code: 'en|af',
                title: 'Afrikaans',
            },
            {
                code: 'en|ar',
                title: 'Arabic',
            },
            {
                code: 'en|ko',
                title: 'Korean',
            },
            {
                code: 'en|lt',
                title: 'Lithuanian',
            },
        ]
        if(this.checkIsLogin() && this.siteSettings?.business_type == 2) {
            this.getLockedEarnedBonus();
            this.getWalletType = this.stateUser?.activeWallet == 'WB' ?? false;
        }
        this.setSectionList()
    },
    components: {
        BonusInformation,
        StakeModal,
        CreditReference,
        RulesModal,
        ClickBetModal,
        LogoutModal,
        LockedBonus,
        WelcomBonus,
        Footer
    },
    methods: {
        openBonusModal(id){
            let bonusModal = new Modal(document.getElementById(id));
            bonusModal.show();
        },
        getIcon(id){
            let imgMap = {
                4 : 'Cricket.webp',
                1 : 'Football.webp',
                2 : 'Tennis.webp',
                6423 : 'American- Football.webp',
                61420 : 'Australian-Rules.svg',
                7511 : 'Baseball.webp',
                7522 : 'basketball.png',
                6 : 'boxing-glove.png',
                11 : 'cycling.png',
                3503 : 'Darts.webp',
                27454571 : 'esportspng.png',
                2152880 : 'gaelic-games.svg',
                3 : 'golf.png',
                4339 : 'Greyhound-Racing.webp',
                468328 : 'Handball.webp',
                7 : 'Horse-Racing-1.webp',
                7524 : 'ice-hocky.webp',
                26420387 : 'martial-arts.png',
                8 : 'moto-sports.png',
                2378961 : 'Politics.png',
                1477 : 'Rugby.webp',
                5 : 'Rugby-Union.webp',
                6422 : 'Snooker.webp',
                10 : 'special-bets.png',
                998917 : 'volleyball.png',

            }
            return imgMap[id] || 'game_default_icon.svg';
        },
        setDefaultImg(event) {
            event.target.src = require('@/assets/images/defaultCasino.png')
        },
        setSectionList() {
            let sectionsList = this.gamesData?.sections;
            const slugToKeyMap = {
                'multiplayer_games': 'multiplayer_games',
                'esports': 'esports',
                'popular_games': 'popular_games',
                'providers': 'providers'
            };
            sectionsList?.forEach(section => {
                const key = slugToKeyMap[section.slug];
                if (key) {
                    this.sectionWiseGamesList[key] = section.games || [];
                }
            });
        },

        getImageUrl(imageName) {
            return require(`@/assets/images/${imageName}`);
        },
        checkEventManageforSingleType(type) {
            if(this.checkIsLogin) {
                let sportEventManage = this.lock_settings?.find(item => item.type == type);
                if(type == 'worli-matka' && sportEventManage && sportEventManage.type_id != null ) {
                    return true
                }
                return !sportEventManage
            }
            return true;
        },
        updateWalletType() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.post(apiName.UPDATE_WALLET_TYPE, {} , {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                            this.getWalletType = !this.getWalletType
                        }
                        else {
                            let user = this.stateUser;
                            user.activeWallet = response.data.data.active_wallet;
                            this.$store.dispatch('setUser', user);
                            this.$emit('success-modal', 'Successfully changed');
                            setTimeout(() => {
                                this.$emit('updateAuthToken', true);
                            },2000)
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.getWalletType = !this.getWalletType
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        getRules() {
            if(this.siteRuleData) {
                return;
            }
            this.$store.dispatch('setGlobalLoader', true);
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let ruleResp = response.data.data;
                            this.siteRuleData = {}
                            ruleResp.forEach(item => {
                                this.siteRuleData[item.type] = item.content;
                            })
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        getBonusLogs() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.get(apiName.GET_BONUS_LOGS, {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.bonusLogs = response.data.data.data;
                        }
                    }
                }
            }).catch(error => {
                console.log(error)
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        getUsedBonus(locked, earned) {
            return parseFloat((earned/locked)*100).toFixed(2);
        },
        getTotalRolling(locked, earned, rolling) {
            return parseFloat((earned/locked)*rolling).toFixed(2);
        },
        getLockedEarnedBonus() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.get(apiName.GET_LOCKED_EARNED_BONUS, {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.lockedEarnedData = response.data.data;
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    console.log(error);
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        logoutServiceCall() {
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                        this.$emit('success-modal', response?.data?.message);
                        localStorage.removeItem('is_offer');
                        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                        this.$store.dispatch('resetAllState');
                        this.$router.push({name:'Home'});
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        clickOnGamestButton(item) {
            if (this.checkIsLogin()) {
                this.closeModal()
                this.$loadCasinoGame.loadGame(item, this.userData);
            } else {
                this.$refs.open_login_modal.click();
            }
        },
        clickOnSportsBook() {
			let item = {
				provider: 'sports_book',
				game_name: 'Sports Book'
			}

			setGameItem(item);
			this.openIframeModalFunction();

		},
        openIframeModalFunction() {
			this.openIframeModal = true;
			this.$refs.iframe_pop_modal_account.click();
		},
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        checkInEventMange(type) {
            return this.eventMange ? this.eventMange?.filter(s => s.type == type).length != 0 ? false : true : true;
        },
        getWalletGateway() {
            this.$router.push({name: 'wallet-gateway'});
        },
        getGlobaLoader() {
            return this.$store.getters.globalLoader;
        },
        siteRulesModal() {
            this.siteRuleModal = new Modal(this.$refs.siteRulesModal);
        },
        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setClickBetValue', null);
            }
        },
        closeModal() {
            this.$refs.close_button.click();
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },

        getClickBetSelectedValue() {
            let inputValues = this.getClickBetValue();
            if (inputValues)
                return inputValues?.filter(item => item?.isSelected)[0]?.value;
        },
        getKFormatter(num) {
            return kFormatter(num);
        },

        setTheme(theme) {
            this.themeName = theme;
            localStorage.setItem('default_class', theme)
            document.documentElement.className = theme;
            this.themeSelection = false;
        },
        navigateToGames(path){
            this.closeModal()
            path == 'all-games' ? this.$router.push({name: path}) : this.$router.push({name: 'games-category', params: {type: path}})
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    },

};
</script>
<style scoped>
.border-none{
    border: none !important;
}
</style>