<template>
    <div class="modal fade match-reminder-modal" id="locked-bonus-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg modal-dialog modal-dialog-centered information-modal-wrapper">
            <div class="modal-content">
                <div class="modal-header">
                        <h4>{{translatedLangData('locked-bonus','Locked Bonus')}}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="locked-bonus-sec">
                        <div class="locked-bonus-list">
                            <ul>
                                <li  v-for="(bonus, b_index) in bonusLogs" :key="b_index">
                                    <div class="exch-locked-bonus-card">
                                        <div class="exch-bonus-rit-bx">
                                            <strong class="xs-bd-font">{{translatedLangData('total-rolling','Total Rolling')}} :- {{ getTotalRolling(bonus.lock_bonus, bonus.earn_bonus, bonus.rolling_amount) }}</strong>
                                            <span class="xs-bd-font float-end">{{ bonus.rolling_amount }}</span>
                                            <div class="exch-bonus-progress-bx">
                                                <div class="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-success" :style="{width: getUsedBonus(bonus.lock_bonus, bonus.earn_bonus) + '%'}"></div>
                                                </div>
                                            </div>
                                            <div class="exch-rolling-amount">
                                                <span class="xs-font font-gray-color">{{ (bonus.status == 'Active' || bonus.status == 'Pending') ? `${bonus.bonus_type} `+translatedLangData('bonus','Bonus')+`:- ${bonus.lock_bonus-bonus.earn_bonus}` : translatedLangData('earn-bonus','Earn Bonus')+`:- ${bonus.earn_bonus}` }}</span>
                                                <span class="xs-font font-gray-color">{{ (bonus.status == 'Active' || bonus.status == 'Pending') ?  translatedLangData('bonus-expiry','Bonus Expiry') +`:- ${bonus.expire_at}` : bonus.status }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-if="!bonusLogs || !bonusLogs.length">
                                    <div class="exch-locked-bonus-card">
                                        <NoDataFound />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NoDataFound from '@/shared/components/no-data/NoDataFound.vue';
export default {
    name: 'LockedBonus',
    props:['bonusLogs'],
    inject:['translatedLangData'],
    components: {
        NoDataFound
    },
}
</script>