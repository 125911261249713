const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue');
const MyBets = () => import(/* webpackChunkName: "my-bets" */ './views/MyBets.vue');
const AccountStatement = () => import(/* webpackChunkName: "account-statement" */ './views/AccountStatement.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');
const ActivityLogs = () => import(/* webpackChunkName: "profit-loss" */ './views/ActivityLogs.vue');

const BetsRoutes = {
    path: '/',
    component: BetsModule,
    children: [
        {
            path: 'my-bets/:type',
            name: 'my-bets',
            component: MyBets,
        },
        {
            path: 'account-statement',
            name: 'account-statement',
            component: AccountStatement,
        },
        {
            path: 'profit-loss',
            name: 'profit-loss',
            component: ProfitLoss,
        },
        {
            path: 'activity-logs',
            name: 'activity-logs',
            component: ActivityLogs,
        },
    ],
}

export default BetsRoutes;