<template>   
   <div class="offcanvas offcanvas-start home-menu-themes" tabindex="-1" id="offcanvasLeft"
        aria-labelledby="offcanvasLeftLabel">
        <div class="offcanvas-header d-block ">
            <div class="list-cont-sec">
               <div ref="close_btn" type="button" class="back-img" data-bs-dismiss="offcanvas"></div>
                <input type="text" class="form-control" :placeholder="translatedLangData('search-market','Search Market')" v-model="searchString" @keyup="this.searchCasinoMatches();">
                <div class="list-cont" v-if="searchResultsShow">
                    <div class="list-sugg">
                        <template v-if="searchResult?.length > 0">
                           <p v-for="(item,index) in searchResult" :key="index" @click="clickOnSportItem(item), $emit('toggleBackButton')"> 
                              {{ item?.event_name }}
                           </p>
                        </template >
                        <template v-if="searchString != '' && searchResult?.length == 0">
                           <p>{{translatedLangData('no-rec-found','No Events Found \'') + searchString + "\'"}}</p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="offcanvas-body">
            <ul class="top-game-sec mt-0">               
                <li class="nav-item">
                    <a class="nav-link" @click="routeAsPerLoginStatus('all-games');closeModal()" href="javascript:void(0)"><span><img src="@/assets/images/all-casino.png" alt="all-casino" loading="lazy"> </span>
                        {{translatedLangData('all-casinos','All Casinos')}}</a>
                </li>
                <li class="nav-item" v-if="checkEventManageforSingleType('sports-book')" @click="routeAsPerLoginStatus('sports-book');closeModal()">
                    <a class="nav-link " href="javascript:void(0)"><span><img src="@/assets/images/vr-sports.png" alt="vr-sports" loading="lazy"></span>
                     {{translatedLangData('sportsbook','SportsBook')}} </a>
                </li>
                <li class="nav-item" @click="routeAsPerLoginStatus('exchange-games');closeModal()">
                    <a class="nav-link " href="javascript:void(0)"><span><img src="@/assets/images/sports.png" alt="sports" loading="lazy"></span>
                        {{translatedLangData('sports','Sports')}}</a>
                </li>

            </ul>
            <ul class="top-game-sec">                
               <!-- non custom sports -->
               <li class="nav-item dropdown" v-for="(sport, n_index) in non_custom_sports" :key="n_index">
                    <a class="nav-link dropdown-toggle" :data-bs-target="'#collapsesport' + n_index"
                    aria-expanded="true" :aria-controls="'collapsesport' + n_index" @click="getLeagues(sport)">
                    <span>
                        <img :src="require(`@/assets/images/non_custom_icons/${getIcon(sport.id)}`)" alt="sport">
                     </span>
                    {{ translatedLangData(sport.slug, capitalizeFirstLetter(sport.name)) }}</a>
                    <ul class="collapse dropdown-menu show inner-dropdown"  :id="'collapsesport' + n_index" v-if="leagueListDropdownRoutes.includes($route.name)">
                        <!-- leagues list -->
                        <template v-if="leagueList?.length > 0">
                           <li v-for="(league, lnIndex) in leagueList" :key="lnIndex"> 
                              <div class=" ps-1 nav-item dropdown">
                                 <a class="nav-link dropdown-toggle" :data-bs-target="'#collapsematch' + lnIndex"
                                 aria-expanded="true" :aria-controls="'collapsematch' + lnIndex" @click="goToSpecificLeague(sport,league)"> {{ league.league_name }}</a>
                                 <ul class="collapse dropdown-menu show inner-dropdown" :id="'collapsematch' + lnIndex" v-if="matchListDropdownRoutes.includes($route.name)">
                                    <!-- match list -->
                                    <template v-if="matchList?.length > 0">
                                       <template v-for="(groupmatch, gm_index) in groupMatchesByDate(matchList)" :key="gm_index">
                                          <div class="grp-match-date" v-if="$route.name != 'sports-event-detail'">{{gm_index}}</div>
                                          <li class="ps-1 nav-item dropdown" v-for="(match, m_index) in groupmatch" :key="m_index">
                                             <a class="nav-link dropdown-toggle" :data-bs-target="'#collapsemarket' + m_index"
                                             aria-expanded="true" :aria-controls="'collapsemarket' + m_index" @click="goToSportEventDetail(match)">{{ match.event_name }}</a>
                                             <ul class="collapse dropdown-menu show inner-dropdown"  :id="'collapsemarket' + m_index" v-if="marketListDropdownRoutes.includes($route.name)">
                                                <!-- markets list -->
                                                <template v-if="marketList?.length > 0">
                                                   <li class="nav-item ps-2 d-flex align-items-center justify-content-between" v-for="(market, mar_index) in marketList" :key="mar_index">
                                                      <a class="nav-link " >{{ market.market_name }}</a>
                                                      <img v-if="market.isVisible" src="@/assets/images/checkbox-green.svg" alt="checkbox-green" loading="lazy" @click="filterMarkets(mar_index);closeModal()">
                                                      <img v-else src="@/assets/images/checkbox-black.svg" alt="checkbox-black" loading="lazy" @click="filterMarkets(mar_index);closeModal()">
                                                   </li>   
                                                </template>
                                                <template v-else>
                                                   <li class="text-white font-13">{{translatedLangData('no-data-found','No Data Found')}}</li>
                                                </template>
                                             </ul>
                                          </li>
                                       </template>
                                    </template>
                                    <template v-else>
                                       <li class="text-white font-13">{{translatedLangData('no-data-found','No Data Found')}}</li>
                                    </template>
                                 </ul>
                              </div> 
                           </li>
                        </template>
                        <template v-else>
                           <li class="text-white font-13">{{translatedLangData('no-data-found','No Data Found')}}</li>
                        </template>
                    </ul>
                </li>
                <!-- custom sports -->
                <li class="nav-item dropdown" v-for="(sport, c_index) in custom_sports" :key="c_index">
                    <a class="nav-link dropdown-toggle" :data-bs-target="'#collapsecustom' + c_index"
                    aria-expanded="true" :aria-controls="'collapsecustom' + c_index" @click="getLeagues(sport)">
                    <span>
                        <img :src="require(`@/assets/images/non_custom_icons/${getIcon(sport.id)}`)" alt="">
                     </span>
                    {{ translatedLangData(sport.slug, capitalizeFirstLetter(sport.name)) }}</a>
                    <ul class="collapse dropdown-menu show inner-dropdown"  :id="'collapsecustom' + c_index" v-if="leagueListDropdownRoutes.includes($route.name)">
                        <!-- leagues list -->
                        <template v-if="leagueList?.length > 0">
                           <li v-for="(league, lncIndex) in leagueList" :key="lncIndex"> 
                              <div class=" ps-1 nav-item dropdown">
                                 <a class="nav-link dropdown-toggle" :data-bs-target="'#collapsematch' + lncIndex"
                                 aria-expanded="true" :aria-controls="'collapsematch' + lncIndex" @click="goToSpecificLeague(sport,league)"> {{ league.league_name }}</a>
                                 <ul class="collapse dropdown-menu show inner-dropdown" :id="'collapsematch' + lncIndex" v-if="matchListDropdownRoutes.includes($route.name)">
                                    <!-- match list -->
                                    <template v-if="matchList?.length > 0">
                                       <template v-for="(groupmatch, gmc_index) in groupMatchesByDate(matchList)" :key="gmc_index">
                                          <div class="grp-match-date" v-if="$route.name != 'sports-event-detail'">{{gmc_index}}</div>
                                          <li class="ps-1 nav-item dropdown" v-for="(match, mc_index) in groupmatch" :key="mc_index">
                                             <a class="nav-link dropdown-toggle" :data-bs-target="'#collapsemarket' + mc_index"
                                             aria-expanded="true" :aria-controls="'collapsemarket' + mc_index" @click="goToSportEventDetail(match)">{{ match.event_name }}</a>
                                             <ul class="collapse dropdown-menu show inner-dropdown"  :id="'collapsemarket' + mc_index" v-if="marketListDropdownRoutes.includes($route.name)">
                                                <!-- markets list -->
                                                <template v-if="marketList?.length > 0">
                                                   <li class="nav-item ps-2 d-flex align-items-center justify-content-between" v-for="(market, marc_index) in marketList" :key="marc_index">
                                                      <a class="nav-link " >{{ market.market_name }}</a>
                                                      <img v-if="market.isVisible" src="@/assets/images/checkbox-green.svg" alt="" @click="filterMarkets(marc_index);closeModal()">
                                                      <img v-else src="@/assets/images/checkbox-black.svg" alt="" @click="filterMarkets(marc_index);closeModal()">
                                                   </li>   
                                                </template>
                                                <template v-else>
                                                   <li class="text-white font-13">{{translatedLangData('no-data-found','No Data Found')}}</li>
                                                </template>
                                             </ul>
                                          </li>
                                       </template>
                                    </template>
                                    <template v-else>
                                       <li class="text-white font-13">{{translatedLangData('no-data-found','No Data Found')}}</li>
                                    </template>
                                 </ul>
                              </div> 
                           </li>
                        </template>
                        <template v-else>
                           <li class="text-white font-13">{{translatedLangData('no-data-found','No Data Found')}}</li>
                        </template>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
   
   <!-------------Header Sidebar End----------------->
   <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#login">
   </div>
   <ClickBetModal @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />
</template>

<script>


import oddsApi from '../../services/click-api';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import * as varConstants from '@/shared/constants/var-constants';
import moment from 'moment';
import NoDataFound from '../no-data/NoDataFound.vue';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import ClickBetModal from '@/modules/account/components/ClickBetModal.vue';

export default {
   components: {
      ClickBetModal,
      NoDataFound,
   },
   name: "SideMenu",
   inject: ['translatedLangData'],
   data() {
      return {
         eventMange: this.$store.getters.eventManage,
         sportId: null,
         leagueName: null,
         loading: false,
         todayMatchesList: null,
         favouriteSportsList: localStorage.getItem(varConstants.FAV_SPORTS_LIST) ? JSON.parse(localStorage.getItem(varConstants.FAV_SPORTS_LIST)) : [],
         searchLoading: false,
         openIframeModal: false,
         searchString: null,
         searchResult:null,
         searchResultsShow:false,
         sportsData:[],
         leagueListDropdownRoutes: ['sports','sports-league','sports-event-detail'],
         matchListDropdownRoutes: ['sports-league','sports-event-detail'],
         marketListDropdownRoutes: ['sports-event-detail'],
         lock_settings: null
      }

   },

   mounted() {
      this.lock_settings = this.$store?.getters?.lock_settings;
   },
   computed: {
      siteSettings() {
         return this.$store.getters.siteSettings;
      },
      domainName() {
         return this.$store.getters.domainName;
      },
      checkIsLogin() {
         return this.$store?.getters?.isAuthenticated;
      },
      custom_sports(){
            if(this.$route.name == 'sports' || this.$route.name == 'sports-league' || this.$route.name == 'sports-event-detail'){
               return this.$store.getters.sportsData?.custom.filter(item => item.id == this.$store.getters.selectedSport)
            }
            else{
               return this.$store.getters.sportsData?.custom
            }
      },
      non_custom_sports(){
            if(this.$route.name == 'sports' || this.$route.name == 'sports-league' || this.$route.name == 'sports-event-detail'){
               return this.$store.getters.sportsData?.non_custom.filter(item => item.id == this.$store.getters.selectedSport)
            }
            else{
               return this.$store.getters.sportsData?.non_custom
            }
      },
      leagueList(){
         const leagues = JSON.parse(JSON.stringify(this.$store.getters?.sideMenuLeagueList));
         return this.$route.name === ('sports-league')
            ? leagues.filter(league => league.league_id == this.$route.params.league_id)
            : ( this.$route.name ===  'sports-event-detail' ? leagues.filter(league => league.league_id == this.$store.getters.selectedLeague) : leagues);
      },
      matchList(){
         const matches = JSON.parse(JSON.stringify(this.$store.getters.sideMenuMatchList))
         
         return this.$route.name === ('sports-event-detail') ? matches.filter(match => match.event_id == this.$store.getters.selectedMatch) : matches;
      },
      marketList(){
         return JSON.parse(JSON.stringify(this.$store.getters.sideMenuMarketList))
      }
   },
   methods: {
      getIcon(id){
         let imgMap = {
               4 : 'cricket.png',
               1 : 'sidemenu6.webp',
               2 : 'sidemenu7.webp',
               6423 : 'American- Football.webp',
               61420 : 'Australian-Rules.svg',
               7511 : 'Baseball.webp',
               7522 : 'basketball.png',
               6 : 'boxing-glove.png',
               11 : 'cycling.png',
               3503 : 'Darts.webp',
               27454571 : 'esportspng.png',
               2152880 : 'gaelic-games.svg',
               3 : 'golf.png',
               4339 : 'Greyhound-Racing.webp',
               468328 : 'Handball.webp',
               7 : 'Horse-Racing-1.webp',
               7524 : 'ice-hocky.webp',
               26420387 : 'martial-arts.png',
               8 : 'moto-sports.png',
               2378961 : 'Politics.png',
               1477 : 'Rugby.webp',
               5 : 'Rugby-Union.webp',
               6422 : 'Snooker.webp',
               10 : 'special-bets.png',
               998917 : 'volleyball.png',

         }
         return imgMap[id] || 'game_default_icon.svg';
      },
      groupMatchesByDate(matches){
         let groupedMtaches = matches.reduce((acc, event) => {
            const date = event.event_date.split('T')[0]; 
            if (!acc[date]) {
                  acc[date] = [];
            }
            acc[date].push(event);
               return acc;
         }, {});
         
         return Object.keys(groupedMtaches)
            .sort((a, b) => new Date(b) - new Date(a))
            .reduce((acc, date) => {
               acc[date] = groupedMtaches[date];
               return acc;
            }, {});
      },
      checkEventManageforSingleType(type) {
         if(this.checkIsLogin) {
               let sportEventManage = this.lock_settings?.find(item => item.type == type);                
               if(type == 'worli-matka' && sportEventManage && sportEventManage.type_id != null ) {
                  return true
               }
               return !sportEventManage
         }
         return true;    
      },
      routeAsPerLoginStatus(route_name){
         this.checkIsLogin 
         ? this.$router.push({ name: route_name })
         : (this.$emit('showErrorModalFunc', this.translatedLangData('login-to-cont','Please Login to continue')), this.$refs.open_login_modal.click());
      },
      filterMarkets(index){
         this.marketList[index].isVisible = !this.marketList[index].isVisible;     
         this.$store.dispatch('setSideMenuMarketList', this.marketList)
      },
      goToSpecificLeague(sport,league){
         this.$store.dispatch('setSelectedLeague',league.league_id)
         this.$router.push({name: 'sports-league', params:{type: sport.name, id: sport.id, league_id:league.league_id}})
      },
      goToSportEventDetail(match){
         this.$store.dispatch('setSelectedMatch', match.event_id)
         this.$router.push({name: 'sports-event-detail', params:{event_id: match.event_id}})
      },
      capitalizeFirstLetter(str) {
         if (!str) return '';
            return str.charAt(0).toUpperCase() + str.slice(1);
      },
      getLeagues(sport){
         this.$router.push({name: 'sports', params:{type: sport.name, id: sport.id}})
      },      
      checkForLockedMatches(matchId, league_id, lockedMatchIds, lockedLeagueIds) {
         return this.checkIsLogin && (lockedMatchIds.length || lockedLeagueIds.length) ? !lockedMatchIds.includes(matchId.toString()) && !lockedLeagueIds.includes(league_id.toString()) : true
      },
      checkInEventMange(type) {
         return this.eventMange ? this.eventMange?.filter(s => s.type == type).length != 0 ? false : true : true;
      },
      async searchCasinoMatches() {
         if (!this.searchLoading) {
            if (this.searchString != '' && this.searchString?.length > 2) {
               this.searchResultsShow=true
               try {
                  this.searchResult = null;
                  this.searchLoading = true;
                  let headers = {
                     'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                  }
                  let data = {
                     "search": this.searchString
                  };
                  api.post(apiName.SEARCH, data, { headers }).then(response => {
                     this.searchLoading = false;
                     if (response && response.status && response.status == 200) {
                        let resData = response.data.data ?? null;
                        this.searchResult = [...resData?.matches];
                     } else {
                        this.searchLoading = false;
                        (this.$emit('showErrorModalFunc',response.data.debug[0]));
                     }
                  }).catch(error => {
                     this.searchLoading = false;
                     if (error) {
                        (this.$emit('showErrorModalFunc',error[0]));
                     }
                  });
               } catch (error) {
                  console.log("Search Error : ", error);
               }
            }
            else {
               this.searchResultsShow = false
               this.searchResult = null
            }
         } else {
            this.searchResult = null
         }
      },

      timeConverter(datetime) {
         return moment(datetime).format('D MMM  h:mm A');
      },
      getMatches(matches, leangeName) {
         this.leagueName = leangeName;
         this.matchesList = matches
      },
      setTheme(theme) {
         document.documentElement.className = theme;
         localStorage.setItem("default_class", theme);
      },

      getEventSportsAllService(type) {
         this.loading = true;
         this.todayMatchesList = null;
         oddsApi.get(apiName.EVENTS_SPORTS_ALL + "?day=" + type).then(response => {
            this.loading = false;
            if (response && response.status == 200) {
               let matchesList = response.data.data
               this.todayMatchesList = matchesList;
            }
         }).catch(error => {
            this.loading = false;
            if (error) {
               console.log("Error : ", error);
            }
         });
      },
      dateTimeConverter(datetime) {
         return moment(datetime).format('HH:MM');
      },

      clickOnSportItem(match){
            this.closeModal()
            this.$router.push('/sports-event-detail/' + match.event_id )
      },
      closeModal() {
         this.searchLoading = false,
         this.searchResult = null;
         this.searchString = ''
         this.$refs.close_btn.click()
      },
      showErrorModalFunc(message) {
			this.$emit('showErrorModalFunc', message);
		},

   }

};
</script>
<style scoped>
.grp-match-date{
   border-bottom: 1px solid #2a2a3d;
   background: #2b2b3b !important;
   font-size: 13px;
   height: 30px;
   line-height: 14px;
   padding: 0 4px;
   font-weight: 500;
   display: flex;
   align-items: center;
   color: #fff;
}
.font-13{
   font-size: 13px;
}
</style>
