<template>
    <section class="no-notification">
        <div class="container">
            <div class="no-notification-icon">
                <img src="@/assets/images/no-data-img.webp" alt="no-data-img" loading="lazy">
            </div>
            <div class="thm-heading">
                <h3 class="profit-color">{{translatedLangData('no-data-found','No data found')}}!</h3>
                <p>{{translatedLangData('cant-find-any-item',"We can't find any item matching your search")}}. </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "NoDataFound",
    inject:['translatedLangData']
}
</script>