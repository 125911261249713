<template>
    <div v-if="$route.name == 'Home' && showButton" class="top-scroll" id="return-to-top" @click.prevent="goToTop()">
        <div class="container">
            <a href="javascript:"><i class="fa-solid fa-angle-up"></i></a>
        </div>
    </div>
</template>

<script>
export default {
    name:'GotoTop',
    data() {
        return {
        showButton: false
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
        if (window.pageYOffset > 300) {
            this.showButton = true;
        } else {
            this.showButton = false;
        }
        },
        goToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
        }
    }
};
</script>