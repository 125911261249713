<template>
    <!----edit stack-->
    <div class="login-sign-up">
        <div class="modal fade modal-footer-sticky worli-Bets-Modal Betamount" data-bs-backdrop="static"
            data-bs-keyboard="false" id="editstackModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true"
            role="dialog">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <div class="thm-heading">
                            <h3>{{ translatedLangData('edit-stack','Edit Stack')}}</h3>
                        </div>
                        <button type="button" @click="resetChips()" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="stakeed-sec withdraw-sec bg-transparent p-0 border-0 singlestakeed-sec">
                            <div class="thm-gradient-but edit-stack-input">
                                <ul v-if="chips">
                                    <li><input type="number" @change="chips.chip_name_1 = getKFormatter(chips.chip_val_1)"
                                            v-model="chips.chip_val_1" class="btn-thm m-auto"></li>
                                    <li><input type="number" @change="chips.chip_name_2 = getKFormatter(chips.chip_val_2)"
                                            v-model="chips.chip_val_2" class="btn-thm m-auto"></li>
                                    <li><input type="number" @change="chips.chip_name_3 = getKFormatter(chips.chip_val_3)"
                                            v-model="chips.chip_val_3" class="btn-thm m-auto"></li>
                                    <li><input type="number" @change="chips.chip_name_4 = getKFormatter(chips.chip_val_4)"
                                            v-model="chips.chip_val_4" class="btn-thm m-auto"></li>
                                    <li><input type="number" @change="chips.chip_name_5 = getKFormatter(chips.chip_val_5)"
                                            v-model="chips.chip_val_5" class="btn-thm m-auto"></li>
                                    <li><input type="number" @change="chips.chip_name_6 = getKFormatter(chips.chip_val_6)"
                                            v-model="chips.chip_val_6" class="btn-thm m-auto"></li>
                                    <li><input type="number" @change="chips.chip_name_7 = getKFormatter(chips.chip_val_7)"
                                            v-model="chips.chip_val_7" class="btn-thm m-auto"></li>
                                    <li><input type="number" @change="chips.chip_name_8 = getKFormatter(chips.chip_val_8)"
                                            v-model="chips.chip_val_8" class="btn-thm m-auto"></li>
                                </ul>
                            </div>
                        </div>
                        <div class="withdraw-fix-sec continue-btn edit-btn">
                            <a @click="resetChips()" class="thm-bor-but thm-but" data-bs-dismiss="modal"
                                aria-label="Close">{{ translatedLangData('cancel','Cancel')}}</a>
                            <a @click="callChangeStakeValue()" class="thm-but">{{ translatedLangData('save','Save')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import { kFormatter } from '@/shared/utils/formatter';


export default {
    name: "StackModal",
    inject: ['translatedLangData'],
    data() {
        return {
            chips: null,
            chipsDummy: null,
            userData: null
        }
    },
    mounted() {
        this.chips = { ...this.$store?.getters?.chips };
        this.chipsDummy = { ...this.$store?.getters?.chips };
        this.userData = this.$store?.getters?.stateUser;
    },
    methods: {
        callChangeStakeValue() {

            let chipsData = {
                "chips": {
                    ...this.chips
                }
            }
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            this.loading = true;
            api.post(apiName.CHANGE_STAKE_VALUES, chipsData, { headers }).then(response => {
                this.loading = false;
                if (response && response.status == 200) {
                    this.$store.dispatch('setChips', this.chips);
                    this.showSuccessModalFunc(this.translatedLangData('saved-successfully','Saved successfully.'))
                    this.$emit('save-btn');
                    this.$refs.close_button.click();
                } else {

                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        getKFormatter(num) {
            return kFormatter(num);
        },
        resetChips() {
            this.chips = { ...this.$store?.getters?.chips };
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    },
    emits: ['close-stake-modal', 'success-modal', 'error-modal', 'save-btn']
};
</script>