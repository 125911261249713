const AuthorizationModule = () => import(/* webpackChunkName: "authorization-module" */ './views/Module.vue');
const ForgetPassword = () => import(/* webpackChunkName: "forget-password" */ './views/ForgetPassword.vue');
const SignUp = () => import(/* webpackChunkName: "sign-up" */ './views/SignUp.vue');
const Login = () => import(/* webpackChunkName: "sign-up" */ './components/Login.vue');

const AuthorizationRoutes = {
    path: '/',
    component: AuthorizationModule,
    children: [
        {
            path: 'login',
            name: 'login',
            component: Login,
        },
        {
            path: 'forget-password',
            name: 'forget-password',
            component: ForgetPassword,
        },
        {
            path: 'sign-up',
            name: 'sign-up',
            component: SignUp,
        }
    ],
}

export default AuthorizationRoutes;