
export const USER_PROFILE='api/user-profile'
export const LOGOUT_API= '/api/logout'
export const AUTH_API= '/api/auth-status'

export const SITE_SETTINGS='api/site-settings';
export const AUTH_STATUS='api/auth-status';
export const GET_FAVOURITE_LIST='api/favorite-list';
export const WALLET_BALANCE='wallet';
export const AUTH_REFRESH='api/auth-refresh';
export const CAPTCHA_API='api/v1/captcha';
export const GET_SPORTS= 'v1/sports/management/getSport';
export const MATCHES_BY_SPORT_ID = 'v1/events/matches/';
export const EVENTS_SPORTS_ALL='v1/events/sports/all';
export const SEARCH='api/search';
export const GET_WALLET_GETWAY='api/get-payment-getway-iframe-url';
export const DEMO_LOGIN_API='api/demo-user-login';
export const getVersionStatus= 'api/site-status-version';
export const v1_SITE_SETTINGS= 'api/v1-site-settings';
export const OPEN_BETS_EVENT_LISTING='api/open-bets-event-listing';
export const GET_CHATBOT_IFRAME = 'api/chatbot/get-iframe-url';
export const GET_CHATBOT_IFRAME_GUEST = 'api/chatbot/get-iframe-url-for-guest';
export const SUBSCRIBE_NOTIFICATION= 'api/subscribe-notification';
export const NOTIFICATIONS_LIST= 'get/all/notification/history';
export const DREAM_CASINO='api/dreamcasino/load-game/';
export const GET_LIVE_POCKER_URL='api/get-live-poker-url/';
export const  GET_MY_MARKETS='api/get-my-markets';
export const GET_SITE_RULES='api/get-site-rules';