const AccountModule = () => import(/* webpackChunkName: "account-module" */ './views/Module.vue');
const Settings = () => import(/* webpackChunkName: "settings" */ './views/Settings.vue');
const ChangePassword = () => import(/* webpackChunkName: "change-password" */ './views/ChangePassword.vue');
const SetStake = () => import(/* webpackChunkName: "set-stake" */ './views/SetStake.vue');
const OneClickbetStake = () => import(/* webpackChunkName: "set-stake" */ './views/oneClickbetSetStakes.vue');

const AccountRoutes = {
    path: '/',
    component: AccountModule,
    children: [
        {
            path: 'settings',
            name: 'settings',
            component: Settings,
        },
        {
            path: 'change-password',
            name: 'change-password',
            component: ChangePassword,
        },
        {
            path: 'set-stake',
            name: 'set-stake',
            component: SetStake,
        },
        {
            path: 'oneclickbet-stake',
            name: 'oneclickbet-stake',
            component: OneClickbetStake,
        },
    ],
}

export default AccountRoutes;