<template>
   <div class="login-sign-up">
      <div class="modal fade modal-footer-sticky match-reminder-modal" id="language_selection_pop_up" tabindex="-1"
         aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
         <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header lan-chang">
                  <h4>{{ translatedLangData('change-language','Change your language')}}</h4>
                  <button type="button" ref="close_ref" class="btn-close" data-bs-dismiss="modal"  @click="ResetLang()"></button>
               </div>
               <div class="modal-body">
                  <div class="langauage-content">
                     <div class="search-lang-input">
                        <input type="search" :placeholder="translatedLangData('search-key','search')" v-model="searchTerm" @input="searchLanguage($event)" class="form-control"> <button
                           class="btn link-btn"><img src="@/assets/images/search-grey-icon.webp" alt="search" loading="lazy"></button>
                     </div>
                     <div class="custom-language-list">
                        <ul v-if="filteredLanguages && filteredLanguages?.length > 0">
                           <li v-for="item in filteredLanguages" :key="item?.lang_code"
                              :class="{ 'active': selectedValue?.lang_code == item?.lang_code }">
                              <div class="form-check" @click="this.selectedValue = item">
                                 <label class="form-check-label">
                                    {{ item.lang_name }}
                                 </label>
                              </div>
                           </li>
                        </ul>
                        <ul v-else>
                           <li>{{translatedLangData('no-data-found','No Data Found')}} '{{searchTerm}}'</li>
                        </ul>
                     </div>
                  </div>

                  <div class="withdraw-fix-sec continue-btn edit-btn khel-btn-group">
                     <a class="btn thm-bor-but thm-but khel-dark-bg" data-bs-dismiss="modal"  @click="ResetLang()"
                        aria-label="Close">{{ translatedLangData('cancel', 'Cancel') }}</a>
                     <a class="btn thm-bor-but thm-but khel-gradient-bg" :class="{'disable-btn' : selectedValue == ''}" @click="selectedlanguage">{{ translatedLangData('update', 'Update') }}</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>

import LanguageService from '@/shared/services/language-service.js'
import { Modal } from 'bootstrap';
export default {
   name: 'GamesPop1',
   inject: ['translatedLangData'],
   data() {
      return {
         openIframeModal: false,
         selectedValue: '',
         searchTerm: '',
         langModal: null,
      }
   },
   mounted(){
      let currentLang = localStorage.getItem('selectedLanguageCode')
        if(currentLang){
            this.selectedValue = this.siteSettings.language_translate_list.find(item => item?.lang_code == currentLang);
        }
        else{
            this.selectedValue = this.siteSettings?.language_translate_list.find(item => item?.lang_code == this.siteSettings.language_code)
        }
        // this.ResetLang();
        this.langModal = new Modal(document.getElementById('language_selection_pop_up'));
   },
   computed: {
      siteSettings() {
         return this.$store.getters.siteSettings
      },
      languagesData() {
         return this.$store.getters.languagesData;
      },
      filteredLanguages() {
         if (this.searchTerm) {
               const searchTermLower = this.searchTerm.toLowerCase();
               return this.languagesData.filter(item => item.lang_name.toLowerCase().includes(searchTermLower));
         }
         return this.languagesData
      },

   },
   methods: {
      searchLanguage(){
         this.selectedValue = ''
      },
      closeModal(){
         if (this.langModal) {
               this.langModal.hide();
         }
      },
      ResetLang(){
         this.searchTerm = '';
         this.closeModal();
         if (localStorage.getItem('selectedLanguageCode')) {
               this.selectedValue = this.languagesData.find(item => item?.lang_code == localStorage.getItem('selectedLanguageCode'));
         } else {
               this.selectedValue = this.languagesData.find(item => item?.lang_code == this.siteSettings?.language_code);
         }
         this.currentLanguage = this.selectedValue?.lang_name;
      },
      yesAgreeMethodCall() {
         this.openIframeModal = true;
         this.$refs.iframe_pop_modal.click();
      },
      closeModal() {
         this.openIframeModal = false
      },
      showErrorModalFunc(message) {
         this.$emit('error-modal', message);
      },
      showSuccessModalFunc(message) {
         this.$emit('success-modal', message);
      },
      async selectedlanguage() {
         this.searchTerm = '';
         this.currentLang == this.selectedValue?.lang_code;
         localStorage.setItem('selectedLanguageCode', this.selectedValue?.lang_code);
         if (this.selectedValue && this.selectedValue?.lang_code === 'en') {
         localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
         this.$parent.langData = "";
         document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
         this.showSuccessModalFunc(this.translatedLangData('language-updated-successfully','Language updated successfully'));
         } 
         else if (this.selectedValue) {
               const aws_url_language = this.siteSettings?.aws_url + '/' + this.selectedValue?.lang_url
               this.$parent.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
               if (this.$parent.langData) {
                  localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
                  document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
                  this.showSuccessModalFunc(this.translatedLangData('language-updated-successfully','Language updated successfully'));
               } else {
                  localStorage.removeItem('selectedLanguageUrl');
                  this.showErrorModalFunc(this.translatedLangData('language-data-is-not-available','Language data is not available'))
               }
         }
      },

   }
}
</script>
<style scoped>
.disable-btn{
   pointer-events: none;
   opacity: 0.5;
}
</style>