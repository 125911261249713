<template>
    <div class="bets-table-box">
        <div class="bets-title">{{ translatedLangData('unmatched-bets', 'Unmatched Bets') }}</div>
        <table class="table">
            <thead>
                <tr>
                    <th style="width: 60%;">{{translatedLangData('runner-name','Runner Name')}}</th>
                    <th style="text-align: center;">{{translatedLangData('bet-price','Bet Price')}}</th>
                    <th style="text-align: center;">{{translatedLangData('bet-size','Bet Size')}}</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
    <div class="bets-table-box">
        <div class="bets-title">{{ translatedLangData('matched-bets', 'Matched Bets') }}</div>
        <table class="table">
            <thead>
                <tr v-if="!averageOdds">
                    <th style="width: 60%;">{{translatedLangData('runner-name','Runner Name')}}</th>
                    <th style="text-align: center;">{{translatedLangData('bet-price','Bet Price')}}</th>
                    <th style="text-align: center;">{{translatedLangData('bet-size','Bet Size')}}</th>
                </tr>
                <tr v-else>
                    <th style="width: 60%;">{{translatedLangData('odds','Odds')}}</th>
                    <th style="text-align: center;">{{translatedLangData('stake','Stake')}}</th>
                    <th style="text-align: center;">{{translatedLangData('pl','P&L')}}</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="!averageOdds">
                    <tr v-for="(item,index) in filteredBets" :key="index" :class="item?.selection_type == 'lay' ? 'pink-color' : 'blue-color'">
                        <td>
                        <span v-if="item?.bet_market_type == 1">{{translatedLangData('match-odds','Match Odds')}}</span>
                        <span v-if="item?.bet_market_type == 2">{{translatedLangData('bookmaker','Bookmaker')}}</span>
                        <span v-if="item?.bet_market_type == 3">{{translatedLangData('fancy','Fancy')}}</span>
                        : {{ item.selection }}
                        </td>
                        <td>{{ item.odds }}</td>
                        <td>{{ item.stake }}</td>
                    </tr>
                </template>
                <template v-else>
                    <!-- Match Odds -->
                    <template v-for="(item,index) in ['main_market','bookmakers','fancies']" :key="index">
                        <template v-if="filteredBets?.[item] && (filteredBets[item].back.length > 0 || filteredBets[item].lay.length > 0)">
                        <tr>
                            <td colspan="3" class="avg-event text-center text-white">
                                {{getMarketName(item)}}
                            </td>
                        </tr>
                        <tr class="blue-color" v-if="filteredBets[item].back.length>0">
                                <td>{{ getAverageOdds(filteredBets[item].back, 'back') }}</td>
                                <td>{{ getAverageStake(filteredBets[item].back, 'back') }}</td>
                                <td>{{ getAveragePL(filteredBets[item].back, 'back') }}</td>
                        </tr>
                        <tr class="pink-color" v-if="filteredBets[item].lay.length>0">
                                <td>{{ getAverageOdds(filteredBets[item].lay, 'lay') }}</td>
                                <td>{{ getAverageStake(filteredBets[item].lay, 'lay') }}</td>
                                <td>{{ getAveragePL(filteredBets[item].lay, 'lay') }}</td>
                        </tr>
                        </template>
                    </template>
                </template>
            </tbody>
        </table>
    </div>

</template>
<script>

import moment from 'moment';
import NoDataFound from '@/shared/components/no-data/NoDataFound.vue';

export default {
   name: 'BetsByMatch',
   inject: ['translatedLangData'],
   props:['averageOdds','filteredBets'],
   data() {
      return {
      };
   },
   methods: {
        getMarketName(item){
            switch (item) {
                case 'main_market':
                return this.translatedLangData('match-odds','Match Odds')
                case 'bookmakers':
                return this.translatedLangData('bookmaker','Bookmaker')
                case 'fancies':
                return this.translatedLangData('fancy','Fancy')
            }
        },
        checkIsLogin(){
                return this.$store.getters.isAuthenticated;
        },
        convertUTCDateToLocalDateHere(timestamp) {
            return moment.unix(timestamp).format('DD MMM YYYY HH:mm:ss');
        },
        getAverageOdds(bets, type) {
            const filteredBets = bets?.filter(bet => bet.selection_type === type);
            if (filteredBets.length === 0) return 0;
            const totalOdds = filteredBets.reduce((acc, bet) => acc + parseFloat(bet.odds), 0);
            return (totalOdds / filteredBets.length).toFixed(2);
        },
        getAverageStake(bets, type) {
            const filteredBets = bets?.filter(bet => bet.selection_type === type);
            if (filteredBets.length === 0) return 0;
            const totalStake = filteredBets.reduce((acc, bet) => acc + parseFloat(bet.stake), 0);
            return totalStake.toFixed(2);
        },
        getAveragePL(bets, type) {
            const filteredBets = bets?.filter(bet => bet.selection_type === type);
            if (filteredBets.length === 0) return 0;
            const totalPL = filteredBets.reduce((acc, bet) => acc + parseFloat(bet.pl), 0);
            return totalPL.toFixed(2);
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal',message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal',message);
        },
   },
   components: { NoDataFound }
}

</script>

<style scoped>
.back-slip {
   background-color: #43ceed;
   border-radius: 2px;
}

.lay-slip {
   background-color: #f796ff;
   border-radius: 2px;
}
.text-white{
   color: white !important;
}
</style>