<template>
   <header v-if="$route.name == 'settings'" class="header-wapper casino-header">
      <div class="main-row top-navbar-container marquee-navbar-container" v-if="siteSettings?.announcement?.msg && !showMarquee?.includes($route.name)">
            <marquee class="MarqueeTxt" :style="{ color: siteSettings?.announcement?.color_code }">
               <p>  {{ siteSettings?.announcement?.msg }}</p>
            </marquee>
      </div>
      <div class="container">
         <div class="header-top">
            <div class="back-button d-flex justify-content-center align-content-center">
                  <router-link :to="{ name: 'Home'}">
                     <img src="@/assets/images/back.webp" alt="back"  loading="lazy">
                  </router-link>
            </div>
            <div class="innerdiv">
                  <div class="col-cls">
                     <p class="b-title">{{ translatedLangData('balance', 'Balance') }}</p>
                     <p class="b-balance">{{ (stateUser.balance).toFixed(2) }}</p>
                  </div>
                  <div class="col-cls">
                     <p class="b-title">{{ translatedLangData('liability', 'Liability') }}</p>
                     <p class="b-lib">{{(parseFloat(stateUser?.exposure) || 0).toFixed(2) }}</p>
                  </div>
                  <a class="btn thm-btn deposit-btn" v-if="siteSettings?.business_type == 2" @click="getWalletGateway()">
                     {{ translatedLangData('deposit', 'Deposit') }}
                  </a>
            </div>
         </div>
      </div>
   </header>

   <header v-else class="header-wapper">
      <div class="container">
         <nav class="navbar">
            <div class="left-header-menu" v-if="!noHeaders.includes($route.name)">
               <button class="toggle-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft"  :aria-label="isOffCanvasVisible ? 'Close menu' : 'Open menu'" 
                  aria-controls="offcanvasLeft">
                  <span class="bar-icon" v-if="!isOffCanvasVisible"><img src="@/assets/images/hamburger.svg" @click="clearSideMenu()" alt="hamburger" loading="lazy"></span>
                  <span class="bar-icon" v-if="isOffCanvasVisible"><img src="@/assets/images/Xcross.svg" @click="clearSideMenu()" alt="Xcross" loading="lazy"></span>
               </button> 
               <router-link :to="{name : 'Home'}" v-if="$route.name == 'CricketFight'">
                  <h4 class="cf-heading">{{ translatedLangData('cricket-fight','Cricket Fight')}}</h4>
               </router-link>
               <router-link :to="{name : 'Home'}" v-else>
                  <img style="height: 40px;" class="logo"
                     :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo"
                     alt="logo" @click="$refs.side_menu.closeModal()" />
               </router-link>

            </div>
            <div v-if="!checkIsLogin() && !noHeaders.includes($route.name)" class="header-right-menu">
               <a href="Javascript:void(0);" @click="demoUserLogin()" class="btn gradient-btn">{{ translatedLangData('demo', 'Demo') }}</a>
               <div class="header-dflex-btn">
                  <a @click="openLogin()" class="btn transperent-btn"><span><img
                           src="@/assets/images/Loginnew.svg" alt="Loginnew"></span>{{ translatedLangData('login', 'Login') }}</a>
                  <router-link v-if="siteSettings && siteSettings?.business_type == 2" :to="{name :'sign-up'}" class="btn gradient-btn"><span><img src="@/assets/images/Signupnew.svg" alt="Signupnew" loading="lazy"></span> 
                  {{ translatedLangData('signup', 'Signup') }}</router-link>
               </div>
            </div>
            <div v-else-if="!noHeaders.includes($route.name)" class="header-right-menu">
              
               <div class="dropdown profile" v-if="!homeHeader.includes($route.name)">
                  <button class="btn gradient-btn oneclick-btn dropdown-toggle" @click="this.clickBetValues = JSON.parse(JSON.stringify($store.getters.clickBetValue))" :class="clickBetOn ? 'clickbeton' : ''" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" aria-label="settings">
                     <img src="@/assets/images/oneclick-icon.gif" alt="oneclick-icon">
                     {{translatedLangData('one-click-bet','One Click Bet')}} 
                  </button>
                  <div class="dropdown-menu khel-top-menu oneclick-dropdown w-100" aria-labelledby="dropdownMenuButton1">
                     <div class="balance-info border-0 pb-0">
                        <div class="form-check form-switch">
                              <label class="form-check-label" for="flexSwitchCheckclickbet">{{translatedLangData('one-clickbet-is','One Click Bet is')}}
                                 {{clickBetOn ? translatedLangData('on','On') : translatedLangData('off','Off')}}</label>
                              <input class="form-check-input" type="checkbox" id="flexSwitchCheckclickbet" :checked="clickBetOn" @click="toggleClickBet()">
                        </div>
                     </div>
                     <div class="ng-star-inserted balance-info border-0 pb-0 mb-0" v-if="clickBetOn">
                        <ClickBet
                           :clickBetValues="clickBetValues"
                           @selectOneClickBetValue="selectOneClickBetValue"
                           @saveClickbet="saveClickbet"
                           @closeDropdown="closeDropdown"
                        />
                     </div>
                  </div>
               </div> 
               <a href="#" v-if="siteSettings?.business_type == 2" @click="getWalletGateway()" class="btn thm-btn khel-dep-btn" aria-label="khel-dep-btn"><span><img src="@/assets/images/cash1.webp" alt="cash1" loading="lazy"></span> {{
                  translatedLangData('deposit', 'Deposit') }}</a>
               <div class="header-portfolio-balance" v-if="homeHeader.includes($route.name)">
                  <div class="portfolio-dflex">
                     <label>{{ translatedLangData('liability', 'Liability') }}</label>
                     <span class="error-color">{{ (parseFloat(stateUser?.exposure) || 0).toFixed(2) }}</span>
                  </div>
                  <div class="portfolio-dflex">
                     <label>{{ translatedLangData('balance', 'Balance') }} </label>
                     <span class="khel-green-color">{{ (stateUser.balance)?.toFixed(2) }}</span>
                  </div>
               </div>
               <div class="dropdown profile">
                  <a class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                     href="#" @click="this.clickBetValues = JSON.parse(JSON.stringify($store.getters.clickBetValue))" aria-label="settings"><img src="@/assets/images/settings.webp" alt="settings" loading="lazy"></a>
                  <div class="dropdown-menu header-top-menu khel-top-menu">
                     <div class="khel-menu-header" onclick="event.stopPropagation();"><span class="d-none"></span> <span>{{stateUser?.userid}}</span></div>
                     <div class="balance-info">
                        <span class="balance-heading" onclick="event.stopPropagation();">{{translatedLangData('bal-info','Balance Information')}}</span>
                        <div class="avail-balance">
                                 <div class="credit-text" onclick="event.stopPropagation();">
                                          <span>{{translatedLangData('available-cred','Available Credit')}}</span>
                                          <span>{{stateUser?.balance.toFixed(2)}}</span>
                                 </div>
                                 <div class="credit-text" onclick="event.stopPropagation();">
                                          <span>{{ translatedLangData('liability', 'Liability') }}</span>
                                          <span>{{(parseFloat(stateUser?.exposure) || 0).toFixed(2)}}</span>
                                 </div>
                                 <div class="balance-btn-sec" v-if="siteSettings?.business_type == 2">
                                          <a @click="getWalletGateway()" class="gredient_bg_btn"> {{translatedLangData('deposit','Deposit')}}</a>
                                          <a @click="getWalletGateway()" class="gredient_bg_btn"> {{translatedLangData('withdraw','Withdraw')}}</a>
                                 </div>
                        </div>
                     </div>
                     <div class="balance-info" v-if="homeHeader.includes($route.name)">
                        <div class="form-check form-switch">
                              <div class="form-check-label" for="flexSwitchCheckbalanceinfo"  onclick="event.stopPropagation();">{{translatedLangData('one-clickbet-is','One Click Bet is')}} {{clickBetOn ? translatedLangData('on','On') : translatedLangData('off','Off')}}</div>
                              <input class="form-check-input" type="checkbox" id="flexSwitchCheckbalanceinfo" :checked="clickBetOn" @click="toggleClickBet()">
                        </div>
                     </div>
                     <div class="ng-star-inserted balance-info" id="betOptions" v-if="clickBetOn && homeHeader.includes($route.name)">
                        <ClickBet
                           :clickBetValues="clickBetValues"
                           @selectOneClickBetValue="selectOneClickBetValue"
                           @saveClickbet="saveClickbet"
                           @closeDropdown="closeDropdown"
                        />
                     </div>
                     <div class="home-menu-themes border-0 bg-transparent p-0">
                        <div class="offcanvas-body p-0">
                              <ul class="top-game-sec mt-0 p-0 bg-transparent accordion" id="accordionExample">
                                 <li class="nav-item p-0">
                                    <router-link class="nav-link " :to="{ name : 'change-password' }">{{translatedLangData('change-password','Change Password')}}</router-link>
                                 </li>
                                 <li class="nav-item p-0 dropdown">
                                    <a class="nav-link accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne-reports" aria-expanded="true" aria-controls="collapseOne-reports" onclick="event.stopPropagation();">{{translatedLangData('reports','Reports')}}</a>
                                    <ul id="collapseOne-reports" class="accordion-collapse collapse show"  aria-labelledby="headingOne-reports"  data-bs-parent="#accordionExample">
                                          <li><router-link class="dropdown-item" :to="{name :'my-bets',params:{ type : 'settled'} }"> {{translatedLangData('my-bets','My Bets')}} </router-link></li>
                                          <li><router-link class="dropdown-item" :to="{name :'my-bets',params:{ type : 'open'} }"> {{translatedLangData('open-bets','Open Bets')}}</router-link></li>
                                    </ul>
                                 </li>
                                 <li class="nav-item p-0 dropdown">
                                    <a class="nav-link accordion-button "  data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onclick="event.stopPropagation();">{{translatedLangData('accounts','Accounts')}}</a>
                                    <ul id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                          <li><router-link class="dropdown-item" :to="{ name :'account-statement'}"> {{translatedLangData('account-statement','Account Statements')}} </router-link></li>
                                          <li><router-link class="dropdown-item" :to="{ name: 'profit-loss'}"> {{translatedLangData('pl-reports','P&L Reports')}}</router-link></li>
                                          <li v-if="siteSettings?.business_type == 2"><a class="dropdown-item" @click="getWalletGateway()"> {{translatedLangData('dep-with-history','Deposit/Withdrawal History')}}</a></li>
                                          <li><router-link class="dropdown-item" :to="{name: 'activity-logs'}"> {{translatedLangData('activity-logs','Activity Logs')}}</router-link></li> 
                                          <li><router-link class="dropdown-item" :to="{ name : 'set-stake'}"> {{translatedLangData('cus-stake-btn','Custom Stake Buttons')}}</router-link></li> 
                                    </ul>
                                 </li>
                                 <li class="nav-item p-0">
                                    <a class="nav-link " @click="getRules()"> {{translatedLangData('rules','Rules')}}</a>
                                 </li>
                                 <li class="nav-item p-0">
                                    <a class="nav-link " href="#install-apk-model" data-bs-toggle="modal"> {{translatedLangData('download-app','Download App')}}</a>
                                 </li>
                                 <li class="nav-item p-0">
                                    <a class="nav-link " href="#language_selection_pop_up" data-bs-toggle="modal"> {{translatedLangData('language','Language')}}</a>
                                 </li>
                                 <li class="nav-item p-0">
                                    <a class="nav-link " href="javascript:void(0)" @click.prevent="logoutServiceCall()">{{ translatedLangData('logout','Logout')}}</a>
                                 </li>
                              </ul> 
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </nav>
      </div>
      <div class="main-row top-navbar-container marquee-navbar-container" v-if="siteSettings?.announcement?.msg && !showMarquee?.includes($route.name)">
         <marquee class="MarqueeTxt" :style="{ color: siteSettings?.announcement?.color_code }">
            <p>{{ siteSettings?.announcement?.msg }}</p>
         </marquee>
      </div>
      <SideMenu ref="side_menu" @showErrorModalFunc="showErrorModalFunc" @showSuccessModalFunc="showSuccessModalFunc"></SideMenu>
   </header>

   <div class="modal fade modal-footer-sticky khel-rules-modal" ref="siteRulesModal" tabindex="-1" aria-labelledby="exampleModalLabel" data-bs-backdrop="static" data-bs-keyboard="false"
        aria-hidden="true">
        <RulesModal :siteRuleData='siteRuleData' />
    </div>
   
</template>

<script>

import SideMenu from "./SideMenu.vue";
import { mapGetters } from 'vuex';
import * as apiName from '@/shared/services/urls';
import api from '@/shared/services/api';
import click_api from '@/shared/services/click-api';
import axios from "axios";
import { setHeaders } from "@/shared/services/headers";
import RulesModal from "@/shared/components/modal/RulesModal.vue";
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";
import ClickBet from "./ClickBet.vue";
import { Dropdown, Modal } from "bootstrap";

export default {
   name: "Header",
   inject: ['translatedLangData'],
   components: {
      SideMenu,
      ClickBet,
      Dropdown,
      RulesModal
   },
   data() {
      return {
         cricketFight_eventName: JSON.parse(localStorage.getItem('crick_fight_match')) ? JSON.parse(localStorage.getItem('crick_fight_match')).event_name : '',
         myContest_eventName: JSON.parse(localStorage.getItem('my_contest')) ? JSON.parse(localStorage.getItem('my_contest')).event_name : '',
         searchText: "",
         showMarquee: [],
         noHeaders: ['sign-up','forget-password'],
         homeHeader: ['Home','CricketFight','MatchDetails','MyContestBetDetails','MyContests','PlaceBet','matka','matka-details','refer-earn'],
         form: {
            type: '1',
            userid: '',
            password: '',
            code:''
         },
         clickBetValues: null,
         non_custom_sports:[],
         custom_sports:[],
         isOffCanvasVisible: false,
         lock_settings:null,
         siteRuleData: null,
      }
   },
   created() { 
      this.fillInputValues();
   },
   mounted(){
      this.lock_settings = this.$store?.getters?.lock_settings;
      this.getSportsList();
      const offcanvasElement = document.getElementById('offcanvasLeft');
      if (offcanvasElement) {
         const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
               if (mutation.attributeName === 'class') {
                  this.isOffCanvasVisible = offcanvasElement.classList.contains('show');
               }
            });
         });
         observer.observe(offcanvasElement, { attributes: true });
      }
      this.siteRulesModal();
   },
   beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
   },
   computed: {
      ...mapGetters({
         siteSettings: 'siteSettings',
         stateUser: 'stateUser',
         clickBetValue: 'clickBetValue',
         loggedUser: 'loggedUser'
      }),
      clickBetOn(){
         return this.$store.getters.isClickBetOn
      },
      sortedNonCustomSports() {
         const order = ['cricket', 'football', 'tennis'];
         return this.non_custom_sports.sort((a, b) => {
            const aIndex = order.indexOf(a.name.toLowerCase());
            const bIndex = order.indexOf(b.name.toLowerCase());

            if (aIndex !== -1 || bIndex !== -1) {
               return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
            }
            return a.rank - b.rank;
         });
      }
   },
   methods: {
      siteRulesModal() {
         this.siteRuleModal = new Modal(this.$refs.siteRulesModal);
      },
      getRules(bonus = false) {
         if(this.siteRuleData && !bonus) {
               this.siteRuleModal.show();
               return;
         }
         if(this.siteRuleData) {
            return;
         } 
         this.$store.dispatch('setGlobalLoader', true);
         let headers = {
               'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
         }
         api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
               this.$store.dispatch('setGlobalLoader', false);
               if (response) {
                  if (response.status == 200) {
                     if (response?.data?.status == 0) {
                           this.$emit('error-modal', response.data.debug[0]);
                     }
                     else {
                           let ruleResp = response.data.data;
                           this.siteRuleData = {}
                           ruleResp.forEach(item => {
                              this.siteRuleData[item.type] = item.content;
                           })
                           if(!bonus) {
                              this.siteRuleModal.show();
                           }
                     }
                  }
               }
         }).catch(error => {
               this.$store.dispatch('setGlobalLoader', false);
               if (error) {
                  this.$emit('error-modal', error[0]);
               }
         });
      },
      checkEventManageforSports(sportId) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.lock_settings?.find(item => item.type == 'sports');
                if(sportEventManage) {
                    let sportIds = sportEventManage.type_id.split(',');
                    return !sportIds.includes(sportId.toString());
                }
            }
            return true;    
        },
      getSportsList(){
            this.loading = true;
            click_api.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports=[]
                        for(var i=0;i<all_sports.length;i++){
                            if(this.checkEventManageforSports(all_sports[i].id)) {
                                if(all_sports[i].is_custom==0){
                                    this.non_custom_sports.push(all_sports[i])
                                }else{
                                    this.custom_sports.push(all_sports[i])
                                }
                                all_sports[i]?.name?.toLowerCase() === 'soccer' ? all_sports[i].name = 'Football' : '';
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list={}
                        sport_list['custom']=this.custom_sports
                        sport_list['non_custom']=this.sortedNonCustomSports
                        this.$store.dispatch('setSportsData', sport_list);
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                }
            });
        },
      clearSideMenu(){
         this.$refs.side_menu.searchString='';
         this.$refs.side_menu.searchResult=null;
      },
      fillInputValues(){
         const clickBetValue = this.getClickBetValue();
         if (clickBetValue) {
            this.clickBetValues = JSON.parse(JSON.stringify(clickBetValue));
         }
         else {
            this.clickBetValues = [
            {
                            value: 100,
                            isSelected: true,
                    },
                    {
                            value: 200,
                            isSelected: false,
                    },
                    {
                            value: 500,
                            isSelected: false,
                    },
                    {
                            value: 1000,
                            isSelected: false,
                    },
                    {
                            value: 1200,
                            isSelected: false,
                    },
                    {
                            value: 1500,
                            isSelected: false,
                    },
                    {
                            value: 1800,
                            isSelected: false,
                    },
                    {
                            value: 2000,
                            isSelected: false,
                    },
            ]
            this.$store.dispatch('setClickBetValue',this.clickBetValues)
         }
      }, 
      getClickBetValue() {
         return this.$store.getters.clickBetValue ? JSON.parse(JSON.stringify(this.$store.getters.clickBetValue)) : null;
      },
      closeDropdown(){
         const dropdownMenu = document.querySelector('.profile .dropdown-menu.show');
         if (dropdownMenu) {
            const headAccElement = document.querySelector('.profile .dropdown-toggle');
            const headAcc = Dropdown.getInstance(headAccElement);
            if (headAcc) {
                  headAcc.hide();
            }
         }
      },
      openLogin(){
         let loginModal = new Modal(document.getElementById('login'))
         if(loginModal){
            loginModal.show();
         }
      },
      toggleClickBet(){
         this.$store.dispatch('setIsClickBetOn', !this.clickBetOn)
         this.clickBetOn == false && this.closeDropdown()
      },
      saveClickbet(){
         this.$store.dispatch('setClickBetValue', JSON.parse(JSON.stringify(this.clickBetValues)));
         this.showSuccessModalFunc(this.translatedLangData('one-clickbt-succ-msg','One Click Bet Value Updated Successfully'));
         this.closeDropdown()
      },
      selectOneClickBetValue(index){
         for (let i = 0; i < this.clickBetValues.length; i++) {
                this.clickBetValues[i].isSelected = false;
            }
         this.clickBetValues[index].isSelected = true;
      },
      demoUserLogin() {
         this.form.type = 1;
         this.form.userid = "demouser";
         this.form.password = "demopass";
         this.loginServiceCall(apiName.DEMO_LOGIN_API, "demo");
      },
      async loginServiceCall(url, type) {
         this.demoLoading = true;
         let data = {
            userid: this.form.userid.toString(),
            type:  this.form.type,
            password: this.form.password,
            cncode: this.form.code,
         };

         api
            .post(url, data)
            .then((response) => {
               this.demoLoading = false;
               if (response) {
                  if (response.status == 200) {
                     if (response?.data?.status == 0) {
                        this.showErrorModalFunc(response.data.debug[0]);
                     } else {
                        this.showSuccessModalFunc(response.data.message);
                        let userData = response.data.data;
                        if(this.loggedUser!=userData.userid){
                           this.$store.dispatch('setClickBetValue',this.inputvalue);
                           this.$store.dispatch('setIsClickBetOn',false);
                        }
                        this.$cookies.set(AUTH_TOKEN, userData.token);
                        this.$store.dispatch("setToken", `Bearer ${userData.token}`);
                        window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                        //for add on all APIs
                        axios.defaults.headers.common[
                           "Authorization"
                        ] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                        this.$store.dispatch('setLoggedUser', userData.userid);
                        setHeaders();
                        this.setUserDataToStore(userData);
                        //this.$router.go();
                        location.reload();
                     }
                  }
               }
            })
            .catch((error) => {
               console.log("Error Login : ", error);
               this.demoLoading = false;
               if (error) {
                  this.showErrorModalFunc(error[0]);
               }
            });
      },
      logoutServiceCall() {
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                        this.$emit('success-modal', response?.data?.message);
                        localStorage.removeItem('is_offer');
                        // remove all cookie
                        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                        this.$store.dispatch('resetAllState');
                        this.$router.push({name:'Home'});
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },

      showErrorModalFunc(message) {
			this.$emit('error-modal', message);
		},
		showSuccessModalFunc(message) {
			this.$emit('success-modal', message);
		},

      setUserDataToStore(userData) {
         let asciiCodes = null;
            for (let i = 0; i < userData.userid.length; i++) {
                asciiCodes += userData.userid.charCodeAt(i)
            }
            let user = {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                rid: userData.rid,
                userid: userData.userid,
                exposure: userData.expl,
                available_credits: userData.available_credits,
                id: userData.id,
                exp: userData.exp,
                notificationSubscribed:null,
                notificationUnsubscribed:null,
                activeWallet: userData.actw,
                asciiCodes: asciiCodes,
                cpi: userData.cpi,
            };
            this.$store.dispatch("setUser", user);
            this.$store.dispatch("setChips", userData.chips);
            this.$store.dispatch("setEventManage", userData?.lock_settings);
      },

      getWalletGateway() {
         this.$router.push({name: 'wallet-gateway'});
      },
      checkForBackArrow() {
         let routeName = this.$route.name;
         let routeType = this.$route.params.type;
         switch (routeName) {
            //checking for show three dot menu
            case 'Home':
            case 'my-market':
               return true;
            case 'games':
               return routeType == 'GAMES';
            default:
               return false
         }
      },
      getTitle() {
         let routeName = this.$route.name;
         if (routeName == 'sports') {
            let routeType = this.$route.params.type;
            switch (routeType) {
               case 'cricket':
                  return 'Cricket';
               case 'soccer':
                  return 'Soccer';
               case 'tennis':
                  return 'Tennis';
               case 'ASSEMBLY_ELECTION':
                  return 'Assembly Election';
               case 'IPL_WINNER':
                  return 'IPL Winner';
               case 'HORSE_RACE':
                  return 'Horse Race'
               case 'GREY_HOUND':
                  return 'Grey Hound'
               default:
                  return 'Sports';
            }
         }
         else if (routeName == 'games') {
            let routeType = this.$route.params.type;
            switch (routeType) {
               case 'LIVE_CASINO':
                  return 'Live Casino';
               case 'WORLD_CASINO':
                  return 'World Casino';
            }
         }
         else if (routeName == 'virtual-sport') {
            return 'Virtual Sports';
         }
         else if (routeName == 'sports-book') {
            return 'Sportsbook';
         }
         else if (routeName == 'matka') {
            return 'Matka';
         }
         else if (routeName == 'notifications') {
            return 'Notifications';
         }
         else if (routeName == 'favourites') {
            return 'Favourites';
         }
         else if (routeName == 'wallet') {
            return 'Wallet';
         }
         else if (routeName == 'wallet-deposit') {
            return 'Wallet Deposit';
         }
         else if (routeName == 'deposit-payment-method') {
            return 'Wallet Payment Method';
         }
         else if (routeName == 'wallet-history') {
            return 'Wallet History'
         }
         else if (routeName == 'wallet-history-detail') {
            return 'Transaction Detail'
         }
         else if (routeName == 'payment-method' || routeName == 'wallet-withdraw') {
            return 'Withdraw Details'
         }
         else if (routeName == 'add-account') {
            return 'Account Details'
         } else if (routeName == 'CricketFight') {
            return 'CRICKET FIGHT'
         }
         else if (routeName == 'MatchDetails' || routeName == 'PlaceBet') {
            return this.cricketFight_eventName
         }
         else if (routeName == 'MyContests') {
            return 'MY CONTESTS'
         }
         else if (routeName == 'MyContestBetDetails') {
            return this.myContest_eventName
         }
         else if (routeName == 'racing-category' || routeName == 'racing-list-details' || routeName == 'racing-carousel') {
            let routeType = this.$route.params.type;
            switch (routeType) {
               case 'HORSE_RACE':
                  return 'Horse Race';
               case 'GREY_HOUND':
                  return 'Grey Hound';
               default:
                  return 'Racing';
            }
         }

      },
      callVoiceSearchFlag() {
         this.$emit('voice-search-flag')
      },
      checkIsLogin() {
         return this.$store?.getters?.isAuthenticated;
      },
      goToHome() {
         this.$router.push({ name: 'Home' });
      },
      searchIcon() {
         if (this.searchText) {
            this.$emit('search-filter', this.searchText)
         }
      }
   },
};

</script>
<style scoped>
.deposit-btn{
   background: var(--button-color) !important;
   padding: 0 15px !important;
   height: 40px !important;
   flex-shrink: 0;
   align-items: center;
   display: flex;
   align-self: center;
   border-radius: 10px !important;
   color: #fff !important;
}
.b-balance{
   color: green;
}
.cf-heading{
       color: white;
    font-size: 16px;
}
</style>