<template>
    <div>
      <div class="radioButton">
        <input type="button"
               class="peerradio"
               :class="val.isSelected ? 'checked' : ''"
               :value="val.value"
               :checked="val.isSelected"
               v-for="(val, index) in clickBetValues"
               :key="index"
               @click="selectOneClickBetValue(index)">
      </div>
      <div class="one-click-buttons">
        <button class="peerradio w-50 bg-transparent" @click="closeDropdown()">
          <router-link :to="{name:'oneclickbet-stake'}" class="edit-bet-stake-btn">{{ translatedLangData('edit-bet-stake','Edit Bet Stake') }}</router-link>
        </button>
        <button class="peerradio w-50 bg-success-cus" @click="saveClickbet()">{{ translatedLangData('save','Save') }}</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    inject:['translatedLangData'],
    props: {
      clickBetValues: {
        type: Array,
        required: true
      }
    },
    methods: {
      selectOneClickBetValue(index) {
        this.$emit('selectOneClickBetValue', index);
      },
      saveClickbet(event) {
        this.$emit('saveClickbet');
      },
      closeDropdown(event) {
        this.$emit('closeDropdown');
      }
    }
  }
  </script>