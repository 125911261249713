<template>

    <template v-if="homeFooter.includes($route.name)">
        <div class="footer-fixed" v-if="homeFooter.includes($route.name)">
            <div class="container">
                <ul class="menu-btm">
                    <li>
                        <router-link :class="{'active':$route.name=='Home'}" :to="{name:'Home'}"><span>
                            <img src="@/assets/images/home-yellow.webp" alt="home-yellow" loading="lazy"></span>{{translatedLangData('home','Home')}}
                        </router-link>
                    </li>
                    <li>
                        <router-link :class="{'active':$route.name=='all-games'}" :to="{name:'all-games'}"><span>
                            <img src="@/assets/images/Casino-menu.webp" alt="Casino-menu" loading="lazy"></span>{{translatedLangData('casinos','Casinos')}}
                        </router-link>
                    </li>
                    <li v-if="showCricketFight.includes($route.name)">
                        <router-link to="/cricket-fight"><span class="whatsup-icon">
                            <img src="@/assets/images/cricket-fight-icon.webp" alt="cricket-fight-icon"  loading="lazy"></span>
                            {{translatedLangData('cricket-fight','Cricket Fight')}}
                        </router-link>
                    </li>
                    <li v-if="checkIsLogin()">
                        <a @click="clickOnMyMarketButton()"><span>
                            <img src="@/assets/images/mybets-icon.svg" alt="mybets-icon" loading="lazy"></span>
                            {{translatedLangData('my-bets','My Bets')}}
                        </a>
                    </li>
                    <li v-else>
                        <router-link to="/sports/cricket/4"><span>
                            <img src="@/assets/images/sports-icon.svg" alt="sports-icon" loading="lazy"></span>
                            {{translatedLangData('sports','Sports')}}
                        </router-link>
                    </li>
                    <li>
                        <a @click="clickOnAccountButton()"><span> 
                            <img src="@/assets/images/More22.webp" alt="More22" loading="lazy"></span>
                            {{translatedLangData('menu','Menu')}}
                        </a>
                    </li>
                
                </ul>
            </div>
        </div>
        <div class="whatsup-fixed-icon" v-if="siteSettings && siteSettings?.business_type == 2 && checkIsLogin() && routePath == 'Home'">
            <div class="container">
                <div class="instant_id_row">
                    <a target="_blank" @click="getParentWhatsappNumber" data-bs-toggle="modal" data-bs-target="#whatsup-depositModal"
                        class="in-image2 create-whatsapp float2 whatsAppSettingsAfterLogin2 signup_btn_wrapper ng-star-inserted">
                        <div class="wa-img" >
                            <img src="@/assets/images/whatsapp12.webp" alt="whatsapp12" loading="lazy" >
                        </div>
                        <!-- <button><span class="blink">Whatsapp for new ID</span></button> -->
                    </a>
                </div>
            </div>
        </div>
        <div class="whatsup-fixed-icon Logout-whatsapp-icn-sec" v-if="siteSettings && siteSettings?.business_type == 2 && !checkIsLogin() && routePath == 'Home'">
            <div class="container">
                <div class="instant_id_row">
                    <a  :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank"
                        v-if="siteSettings?.social_link && siteSettings?.social_link.whatsappno1"
                        class="in-image2 create-whatsapp float2 whatsAppSettingsAfterLogin2 signup_btn_wrapper ng-star-inserted">
                        <div class="wa-img" >
                            <img src="@/assets/images/whatsapp12.webp" alt="whatsapp12" loading="lazy">
                        </div>
                        <!-- <button><span class="blink">{{translatedLangData('get-an-id-instantly-on-whatsapp','Get an ID instantly on whatsapp')}}</span></button> -->
                    </a>
                </div>
            </div>
        </div>
        <GotoTop />
    </template>

    <template v-else-if="!['settings','MatchDetails','matka-details'].includes($route.name)">
        <div class="bottom-nav-container">
            <div class="flex-bottom">
                <div class="button-outer">
                    <a @click="goToHome(),closeMyBets()" class="khel-home-icn" href="#" aria-label="goToHome">
                        <img src="@/assets/images/home-yellow-footer.svg" alt="home-yellow-footer" loading="lazy">
                    </a>
                    <div class="walletbg inner-liability">
                        <p>{{ translatedLangData('balance', 'Balance') }}: <span class="balance">  {{ parseInt(userData?.balance || 0).toFixed(2) }}</span></p>
                        <p>{{ translatedLangData('liability', 'Liability') }} : <span class="liability"> {{ (parseFloat(userData?.exposure) || 0).toFixed(2) }} </span></p>
                    </div>
                </div>
                <div v-if="checkIsLogin() && (($route.name === 'sports-event-detail' && hasOpenbets && hasOpenbets.length > 0) || (openBetsIcon.includes($route.name) && marketList && marketList.length > 0))">
                    <div class="d-flex felx-column align-items-center">
                       <div v-if="showMybets" @click.prevent="openMybets()" class="my-bets-img">
                            <span><img src="@/assets/images/mybets-icon.svg" alt="mybets-icon" loading="lazy"></span>
                            <span>{{translatedLangData('my-bets','My Bets')}}</span>
                       </div>
                       <div v-else @click.prevent="closeMyBets()" class="khel-bet-close-modal">
                         <span><img src="@/assets/images/close-modal.svg" loading="lazy" alt="close-modal"></span>
                       </div>
                    </div>
                </div>
                <!-- <div v-if="checkIsLogin() && openBetsIcon.includes($route.name) && marketList && marketList?.length > 0">
                    <div class="d-flex felx-column align-items-center">
                       <div v-if="showMybets" @click.prevent="openMybets()" class="my-bets-img">
                            <span><img src="@/assets/images/mybets-icon.svg"></span>
                            <span>{{translatedLangData('my-bets','My Bets')}}</span>
                       </div>
                       <div v-else @click.prevent="closeMyBets()" class="khel-bet-close-modal">
                         <span><img src="@/assets/images/close-modal.svg"></span>
                       </div>
                    </div>
                </div> -->
                <div class="button-outer d-flex right-portion">
                    <span>
                        <a @click="goback(), closeMyBets()"><img src="@/assets/images/arrowBottom.svg" alt="arrowBottom" loading="lazy" class="btn-nav-btm"></a>
                    </span>
                    <span  v-if="menuTabActive" class="mlt-10">
                        <img  @click="closeMyBets(), clickOnAccountButton()" class="btn-nav-btm" src="@/assets/images/menuicon.png" alt="menuicon" loading="lazy" >
                    </span>
                    <span v-else  class="mlt-10">
                        <img @click="closeMyBets() ,clickOnAccountButton(true)"  _ngcontent-wwp-c75="" class="btn-nav-btm" src="https://iriscdn.b-cdn.net/kheloyar/menuItems/close.svg" alt="close" loading="lazy" > 
                    </span>
                </div>
            </div>
        </div>
    </template>

    <div ref="open_account_menu" style="display: none;" data-bs-toggle="modal" data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"></div>

    <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#login"></div>

    <WhatsAppModal :whatsappNumbers="whatsappNumbers" />
    <OpenBets />
    <ChatComponent v-if="routePath == 'Home'" @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />

</template>

<script>
import WhatsAppModal from '@/shared/components/modal/whatsAppModal.vue';
import GotoTop from '@/shared/components/footer/GotoTop.vue';
import OpenBets from '@/modules/sports/components/openBetsFooter.vue';
import {Modal} from 'bootstrap';
import ChatComponent from '@/modules/chat/views/ChatComponent.vue';
import * as apiName from '@/shared/services/urls';
import api from '@/shared/services/api';
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";


export default {
    name: "Footer",
    emits: ['error-modal', 'success-modal'],
    inject: ['translatedLangData'],
    components: {
        ChatComponent,
        WhatsAppModal,
        OpenBets,
        GotoTop
    },
    data(){
        return{
            menuTabActive:true,
            whatsappNumbers:[],
            // showChat: false,
            homeFooter: ['Home','CricketFight','MyContestBetDetails','MyContests','PlaceBet','refer-earn','matka'],
            showCricketFight:['matka','matka-details','Home','refer-earn'],
            openBetsIcon:['inplay-games','exchange-games','sports','sports-league'],
            openbetsModal: null,
            marketList: null,
            // showMybets: true
        }
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        userData() {
            return this.$store.getters.stateUser;
        },
        hasOpenbets() {
            return this.$store.getters.hasOpenbets;
        },
        routePath() {
			return this.$route.name
		},
        showMybets() {
         return this.$store.getters.showMybets;
      },
    },
    mounted(){
        if(this.checkIsLogin()){
            this.getMymarket();
        }
    },
    methods: {
        getMymarket() {
            this.marketList = [];
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.get(apiName.GET_MY_MARKETS + '/sport',{headers}).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                this.loading = false;
                this.marketList = [];
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.marketList = response.data.data;
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        openMybets(){
            this.openbetsModal = new Modal(document.getElementById('openbetModal'));
            this.openbetsModal.show();
            this.$store.dispatch('setShowMybets', false);
        },
        closeMyBets(){
            if(this.openbetsModal){
                this.openbetsModal.hide();
                this.$store.dispatch('setShowMybets', true);
            }
        },
        closeMenuModal(){
            let allModals = document.querySelector('.menu-category-modal.show');
            if(allModals) {
                let modalInstance = Modal.getInstance(allModals);
                modalInstance.hide();
            }
        },
        goToHome(){
            this.closeMenuModal();
            this.menuTabActive = true;
            this.$router.push({name: 'Home'})
        },
        goback(){
            this.closeMenuModal();
            this.menuTabActive = true;
            this.$router.back();
        },
        async getParentWhatsappNumber() {
            if (this.checkIsLogin()) {
                this.loading = true;
                try {
                    const cpiExists = this.$store.getters.stateUser?.cpi;
                    const supportNumbers = this.siteSettings.support_numbers || [];
                    if (!cpiExists) {
                        return;
                    }
                    const now = new Date();
                    const totalMinutes = now.getHours() * 60 + now.getMinutes();
                    this.whatsappNumbers = supportNumbers
                        .filter(entry => entry.id === cpiExists)
                        .flatMap(entry => entry.categories.map(category => {
                            const whatsappNumbers = category.value.split(',');
                            const selectedNumberIndex = totalMinutes % whatsappNumbers.length;
                            return {
                                category_name: category.name,
                                whatsapp_number: whatsappNumbers[selectedNumberIndex]
                            };
                        }));
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.showErrorModalFunc(error[0]);
                }
            }
        },
        clickOnAccountButton(type=false) {
            if (this.checkIsLogin()) {
                this.$refs.open_account_menu.click();
                this.menuTabActive = !this.menuTabActive
                if(type){
                    this.closeMenuModal();
                }
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },
        clickOnMyMarketButton() {
            if (this.checkIsLogin()) {
                this.$router.push({ name: 'my-market' });
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
};
</script>

<style scoped>
.footer-list-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>