<template>
    <div class="install-apk-model">
        <div class="modal fade match-reminder-modal" id="install-apk-model" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                            <h4>{{translatedLangData('how-to-install-apk','How to Install APK')}}</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="currentTab = 'android'">
                            <!-- <img src="@/assets/images/apk-modal-icon.svg" alt=""> -->
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="install-app-content">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" :class="currentTab == 'android' ? 'active' : ''" id="home-tab" data-bs-toggle="tab"
                                        data-bs-target="#home" type="button" role="tab" aria-controls="home" @click="currentTab = 'android'"
                                        aria-selected="true">{{translatedLangData('android','Android')}}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" :class="currentTab == 'ios' ? 'active' : ''" id="profile-tab" data-bs-toggle="tab"
                                        data-bs-target="#profile" type="button" role="tab" aria-controls="profile" @click="currentTab = 'ios'"
                                        aria-selected="false">{{translatedLangData('ios','iOS')}}</button>
                                </li>

                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade" :class="currentTab == 'android' ? 'show active' : ''" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <div class="how-install-content">
                                        <img src="@/assets/images/android.png" alt="android" loading="lazy">
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="profile" :class="currentTab == 'ios' ? 'show active' : ''" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="how-install-content">
                                        <img src="@/assets/images/ios.png" alt="ios" loading="lazy">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn close-btn close-thm-btn" data-bs-dismiss="modal" @click="currentTab = 'android'">{{translatedLangData('close','Close')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DownloadApkModal',
    inject:['translatedLangData'],
    data(){
        return{
            currentTab :'android'
        }
    }
}
</script>

<style></style>