<template>
        <div class="modal-dialog onload-modal  modal-dialog-centered">
            <div class="modal-content" style="background-color: transparent !important;">
                <div class="container">
                    <div class="recent-bank-sec bg-wrong">
                        <div class="recent-bank">
                            <div class="bank-icon">
                                <img src="@/assets/images/wrong-icon.webp" alt="wrong-icon" loading="lazy">
                            </div>
                            <div class="bank-con">
                                <h6>{{translatedLangData('wrong','Wrong')}}!</h6>
                                <span>{{ message }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

</template>
<script>
export default {
    name: "ErrorModal",
    props: ['message'],
    inject:['translatedLangData']
}
</script>