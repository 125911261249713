<template>

    <div class="modal fade game-play-modal" data-bs-backdrop="static" data-bs-keyboard="false" id="iframeModalMain" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen" style="max-width: 100%;">
            <div v-if="gameData" class="modal-content">
                <div class="modal-header">
                    <div class="game-header-content">
                        <div class="game-bak-close-block">
                            <a ref="close_button" @click="$emit('closeGame')" data-bs-dismiss="modal"><img
                                    src="@/assets/images/home-yellow.webp" alt="home-yellow" loading="lazy"></a>
                            <strong style="color: black;">{{ gameData?.gameName }}</strong>
                        </div>
                        <div class="close-game-btn">
                            <a data-bs-dismiss="modal" @click="$emit('closeGame')"><img
                                    src="@/assets/images/close-btn.svg" alt="close-btn" loading="lazy"></a>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="game-iframe-sec">
                        <div v-if="this.gameData.gameUrl" class="iframe-content">
                            <iframe :src="this.gameData.gameUrl" frameborder="0"
                                style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
                                height="100%" width="100%" allow="autoplay; fullscreen; picture-in-picture"
                                allowfullscreen title="Tr&amp;eacute;ning TEXT"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

export default {
    name: 'IFrameModal',
    props: ['gameData'],
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>