<template>
    <div class="messsage-fixed-btn-sec">
        <div class="support-chat" :class="!checkIsLogin ? 'chat-icon-right' :''">
            <div class="container">
                <a href="Javascript:void(0)" type="button" data-bs-target="#usernamemodal" data-bs-toggle="modal" @click="toggleChatModal()" ref="chatOpenCloaseBtn"><img src="@/assets/images/Live-ChatFill.webp" alt="Live-ChatFill" loading="lazy"></a>
            </div>
        </div>
    </div>
    <div class="modal fade" id="usernamemodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        data-bs-keyboard="false" >
        <div class="modal-dialog  thm-chat-modal-sec">
            <div class="modal-content">
                <iframe id="chat_modal" :src="iframeUrl" marginwidth="0" marginheight="0" frameborder="0" width="100%"
                    height="100%" scrolling="no" allowfullscreen="allowfullscreen" allow="microphone; camera" v-if="iframeUrl"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';

export default {
    name: 'ChatComponent',
    emits: ['error-modal','success-modal'],
    data() {
        return {
            user_id: null,
            iframeUrl: null,
        }
    },
    computed: {
        userName() {
            return this.$store.getters.stateUser?.userid || null;
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        }
    },
    mounted() {
        const guestUserName = localStorage.getItem('guestUserName')
        if (guestUserName && !this.userName && !this.checkIsLogin) {
            this.user_id = guestUserName;
        } else {
            this.user_id = this.userName
        }
    },
    methods: {
        toggleChatModal() {
            if(!this.iframeUrl) {
                const headers = {
                    'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                };
    
                const userid = this.user_id;
                const apiEndpoint = this.checkIsLogin ? apiName.GET_CHATBOT_IFRAME : apiName.GET_CHATBOT_IFRAME_GUEST;
    
                const requestData = userid && !this.checkIsLogin ? { userid } : {};
    
                api.post(apiEndpoint, requestData, { headers }).then(res => {
                    window.addEventListener('message', this.handleMessage);
                    console.log('chat_bot',res.data.chat_bot_url);
                    this.iframeUrl = res.data.chat_bot_url;
                    console.log('iframe',this.iframeUrl)
                }).catch(error => {
                    this.showErrorModalFunc(error);
                });
            }
        },
        handleMessage(event) {
            if (event.data.userName) {
                this.user_id = event.data.userName;
                localStorage.setItem('guestUserName', this.user_id)
            }
            if (event.data === 'closeChatModal') {
                this.$refs.chatOpenCloaseBtn.click();
                this.iframeUrl = null;
                window.removeEventListener('message', this.handleMessage);
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }

}
</script>

<style>
.chat-back-arrow {
    height: 15px;
    vertical-align: text-top;
}

@media screen and (max-width: 575px) {
    .modal-dialog.thm-chat-modal-sec {
        margin: 0 !important;
    }
}

.modal-backdrop {
    z-index: 0 !important;
}
</style>