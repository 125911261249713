import { getSubtractedDaysDate } from '@/shared/utils/date-time';
import moment from 'moment';
import * as apiName from '../services/urls';
import api from '../services/api';
import { AUTH_TOKEN } from "../../shared/constants/cookies-const";

const state = {
    siteSettings: null,
    gamesData: {
        sections: [],
        providers: []
    },
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    isClickBetOn: false,
    clickBetValue: null,
    favouriteGames:null,
    globalLoader:false,
    depositAmount: 0,
    captchaData: null,
    notification:0,
    languagesData:null,
    siteVersion:null,
    underMaintenance:null,
    hasOpenbets:null,
    totalOpenBetsList:null,
    sportsData: null,
    sideMenuLeagueList: null,
    sideMenuMatchList: null,
    sideMenuMarketList: null,
    selectedSport: null,
    selectedLeague: null,
    selectedMatch: null,
    selectedProviderName: null,
    successMsg: null,
    errorMsg: null,
    showMybets: null,
    loggedUser:null,
};
const getters = {
    siteVersion: state => state?.siteVersion,
    underMaintenance: state => state?.underMaintenance,
    siteSettings: state => state?.siteSettings,
    gamesData: state => state?.gamesData,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    isClickBetOn: state => state?.isClickBetOn,
    favouriteGames:state => state?.favouriteGames,
    globalLoader:state => state?.globalLoader,
    depositAmount:state => state?.depositAmount,
    captchaData: state => state?.captchaData,
    getNotification: state => state?.notification,
    languagesData: state => state?.languagesData,
    hasOpenbets: state => state?.hasOpenbets,
    totalOpenBetsList: state => state?.totalOpenBetsList,
    sportsData: state => state?.sportsData,
    sideMenuLeagueList: state => state?.sideMenuLeagueList,
    sideMenuMatchList: state => state?.sideMenuMatchList,
    sideMenuMarketList: state => state?.sideMenuMarketList,
    selectedSport: state => state?.selectedSport,
    selectedLeague: state => state?.selectedLeague,
    selectedMatch: state => state?.selectedMatch,
    selectedProviderName: state => state?.selectedProviderName,
    successMsg: state => state?.successMsg,
    errorMsg: state => state?.errorMsg,
    showMybets: state => state?.showMybets,
    loggedUser: state => state?.loggedUser,
};
const actions = {
    setSiteVersion({ commit }, siteVersion) {
        commit('setSiteVersion', siteVersion);
    },
    setUnderMaintenance({ commit }, underMaintenance) {
        commit('setUnderMaintenance', underMaintenance);
    },
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setGamesData({ commit }, payload) {
        commit('setGamesData', payload);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setIsClickBetOn({ commit }, isClickBetOn) {
        commit('setIsClickBetOn', isClickBetOn);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setDepositAmount({ commit }, depositAmount) {
        commit('setDepositAmount', depositAmount);
    },
    setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    },
    setNotification({ commit }, notification) {
        commit('setNotification', notification);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
    setHasOpenbets({ commit }, hasOpenbets) {
        commit('setHasOpenbets', hasOpenbets);
    },
    setShowMybets({ commit }, showMybets) {
        commit('setShowMybets', showMybets);
    },
    async setTotalOpenBetsList({ commit },id) {
        let headers = {
            'Authorization': `Bearer ${window.cookies.get(AUTH_TOKEN)}`
        }
        let data = {
            "type": 'sport',
            "sub_type": id,
            "status": 'open',
            "page_entries": 50,
            "from": moment(new Date(getSubtractedDaysDate(new Date(), 15))).format('YYYY-MM-DD'),
            "to": moment(new Date()).format('YYYY-MM-DD')
        }
        await api.post(apiName.OPEN_BETS_EVENT_LISTING + '?page=1', data, {headers}).then(response => {
            const responseData = response;
            commit('setTotalOpenBetsList', responseData)
        }).catch(error => {
            console.log(error);
        });

    },
    setSportsData({ commit }, sportsData) {
        commit('setSportsData', sportsData);
    },
    setSideMenuLeagueList({ commit }, sideMenuLeagueList) {
        commit('setSideMenuLeagueList', sideMenuLeagueList);
    },
    setSideMenuMatchList({ commit }, sideMenuMatchList) {
        commit('setSideMenuMatchList', sideMenuMatchList);
    },
    setSideMenuMarketList({ commit }, sideMenuMarketList) {
        commit('setSideMenuMarketList', sideMenuMarketList);
    },
    setSelectedSport({ commit }, selectedSport) {
        commit('setSelectedSport', selectedSport);
    },
    setSelectedLeague({ commit }, selectedLeague) {
        commit('setSelectedLeague', selectedLeague);
    },
    setSelectedMatch({ commit }, selectedMatch) {
        commit('setSelectedMatch', selectedMatch);
    },
    setSelectedProviderName({ commit }, selectedProviderName) {
        commit('setSelectedProviderName', selectedProviderName);
    },
    showSuccessModal({ commit }, message) {
        commit('showSuccessModal', message);
        setTimeout(() => {
            commit('showSuccessModal', null);
        }, 2000);
    },
    showErrorModal({ commit }, message) {
        commit('showErrorModal', message);
        setTimeout(() => {
            commit('showErrorModal', null);
        }, 2000);
    },
    setLoggedUser({ commit }, loggedUser) {
        commit('setLoggedUser', loggedUser);
    },
};
const mutations = {
    setSiteVersion(state, siteVersion) {
        state.siteVersion = siteVersion;
    },
    setUnderMaintenance(state, underMaintenance) {
        state.underMaintenance = underMaintenance;
    },
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings;
    },
    setGamesData(state, { sections, providers }) {
        if (sections !== undefined) {
            state.gamesData.sections = sections;
        };
        if (providers !== undefined) {
        state.gamesData.providers = providers;
        };
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setIsClickBetOn(state, isClickBetOn) {
        state.isClickBetOn = isClickBetOn;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    setDepositAmount(state, depositAmount){
        state.depositAmount =  depositAmount
    },
    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
    setNotification(state) {
        state.notification = state.notification+1;
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setHasOpenbets(state, hasOpenbets) {
        state.hasOpenbets = hasOpenbets;
    },
    setTotalOpenBetsList(state, totalOpenBetsList) {
        state.totalOpenBetsList = totalOpenBetsList;
    },
    setSportsData(state, sportsData) {
        state.sportsData = sportsData;
    },
    setSideMenuLeagueList(state, sideMenuLeagueList) {
        state.sideMenuLeagueList = sideMenuLeagueList;
    },
    setSideMenuMatchList(state, sideMenuMatchList) {
        state.sideMenuMatchList = sideMenuMatchList;
    },
    setSideMenuMarketList(state, sideMenuMarketList) {
        state.sideMenuMarketList = sideMenuMarketList;
    },
    setSelectedSport(state, selectedSport) {
        state.selectedSport = selectedSport;
    },
    setSelectedLeague(state, selectedLeague) {
        state.selectedLeague = selectedLeague;
    },
    setSelectedMatch(state, selectedMatch) {
        state.selectedMatch = selectedMatch;
    },
    setSelectedProviderName(state, selectedProviderName) {
        state.selectedProviderName = selectedProviderName;
    },
    showSuccessModal(state, message) {
        state.successMsg = message
    },
    showErrorModal(state, message) {
        state.errorMsg = message
    },
    setShowMybets(state, showMybets) {
        state.showMybets = showMybets
    },
    setLoggedUser(state, loggedUser) {
        state.loggedUser = loggedUser;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};