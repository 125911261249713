export const TYPES =[
    {
        id: 1,
        type: 'Open Bets'
    },
    {
        id: 2,
        type: 'Settled Bets'
    },
    {
        id: 3,
        type: 'Statement'
    },
    {
        id: 4,
        type: 'Win & Loss'
    }

]

export const GAME_TYPES = [
    {
        id: 1,
        type: 'Sports'
    },
    {
        id: 2,
        type: 'Games'
    }
]

export const SPORTS_SUB_TYPES = 
[
    {
        id:'ALL',
        type:'All'
    },
    {
        id:4,
        type:'Cricket'
    },
    // {
    //     id:1000,
    //     type:'Virtual Sports'
    // },
    {
        id:1,
        type:'Soccer'
    },
    {
        id:2,
        type:'Tennis'
    },
    // {
    //     id:502,
    //     type:'Assembly Ellection'
    // },
    // {
    //     id:506,
    //     type:'PSL Winner'
    // },
    // {
    //     id:507,
    //     type:'IPL Winner'
    // },
    // {
    //     id:509,
    //     type:'Kabaddi'
    // },
    // {
    //     id:513,
    //     type:'BPL Winner'
    // }
]

export const GAME_SUB_TYPES = 
[
    {
        id:999,
        type:'Live Casino'
    },
    {
        id:4,
        type:'Matka'
    },
    {
        id:2222,
        type:'World Casino'
    }
]


export const BET_TYPES =[
    {
        id:2,
        type:'Settle Bets'
    },
    {
        id:4,
        type:'Void'
    },
    {
        id:5,
        type:'Invalid'
    }
]


//for statements
export const REPORT_TYPES =[
    {
        id:'all',
        type:'All'
    },
    {
        id:'dw',
        type:'Deposit/Withdrawal'
    },
    {
        id:'games',
        type:'Games'
    }
]

export const SPORTS_TYPES =[
    {
        id:'all',
        type:'All'
    },
    {
        id:4,
        type:'Cricket'
    },
    {
        id:1,
        type:'Soccer'
    },
    {
        id:2,
        type:'Tennis'
    }
]

//win and loss types

export const WIN_LOSS_TYPES =[
    {
        id:111,
        type:'All'
    },
    {
        id:1,
        type:'Sports'
    },
    {
        id:2,
        type:'Games'
    }
]

export const WIN_GAMES_TYPES =[
    {
        id:'ALL',
        type:'All'
    },
    {
        id:'worli',
        type:'Matka market'
    },
    {
        id:'casino',
        type:'casino'
    },
    {
        id:'sportbook',
        type:'Sportbook'
    }
]


export const PAGE_TYPE={
    USER_PROFILE:"USER_PROFILE",
    ACCOUNT_STATEMENT:"ACCONT_STATEMENT",
    WITHDRAW:"WITHDRAW",
    TRANSACTION_HISTORY:"TRANSACTION_HISTORY",
    OPEN_BETS:"OPEN_BETS",
    CHIP_SETTING:"CHIP_SETTING",
    PROFIT_LOSS:"PROFIT_LOSS",
    SETTLED_BETS:"SETTLED_BETS"
}

export var NEW_STATIC_GAMES =[
    {
        id: 'all',
        type: 'All'
    },
    {
        id:4,
        type:'Cricket'
    },
    {
        id:1,
        type:'Football'
    },
    {
        id:2,
        type:'Tennis'
    },
    {
        id: "dw",
        type: 'Deposit/Withdraw'
    },
    {
        id: "matka",
        type: 'Matka'
    },
    {
        id: "casino",
        type: 'Casino'
    },
    {
        id: "sportsbook",
        type: 'Sportsbook'
    },
    {
        id: "premium",
        type: 'Premium '
    },
    {
        id: "virtual_sport",
        type: 'Virtual Sports'
    },
    {
        id: "fantasy_cricket",
        type: 'Fantasy Cricket'
    }
]


export const BET_STATUS =[
    {
        id: 1,
        type: 'open'
    },
    {
        id: 2,
        type: 'settled'
    },
    {
        id: 4,
        type: 'void'
    },
    {
        id: 5,
        type: 'invalid'
    },
    {
        id: 6,
        type: 'cancelled'
    }
]



