const SportsModule = () => import(/* webpackChunkName: "sports-module" */ './views/Module.vue'); 
const Sports = () => import(/* webpackChunkName: "sports" */ './views/Sports.vue');
const SportEventDetail = () => import(/* webpackChunkName: "sport-event-detail" */ './views/SportEventDetail.vue');
const InplaySports = () => import(/* webpackChunkName: "inplay-sports" */ './views/InplaySports.vue');
const InplayGames = () => import(/* webpackChunkName: "sport-sports" */ './views/inplayGames.vue');

const SportsRoutes =   {
    path: '/',
    component: SportsModule,
    children: [
      {
        path: 'sports/:type/:id/:league_id',
        name: 'sports-league',
        component: Sports
      },
      {
        path: 'sports/:type/:id',
        name: 'sports',
        component: Sports
      },
      {
        path: 'sports-event-detail/:event_id',
        name: 'sports-event-detail',
        component: SportEventDetail
      },
      {
        path: 'inplay-sports/:type',
        name: 'inplay-sports',
        component: InplaySports
      },
      {
        path: 'inplay-games',
        name: 'inplay-games',
        component: InplayGames
      },
      {
        path: 'exchange-games',
        name: 'exchange-games',
        component: InplayGames
      },
    ],
  }

export default SportsRoutes;