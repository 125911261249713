import { h, render } from 'vue';
import { Modal } from 'bootstrap';
// import GamesIframe from '@/shared/components/games/GamesIframe.vue';
import GamesIframe from '@/modules/games/components/IFrameModal.vue';
import GamePop1 from '@/shared/components/modal/GamesPop1.vue';
import GamePop2 from '@/shared/components/modal/GamesPop2.vue';
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';

const loadCasinoGame = {
    gameData: {
        gameUrl: null,
        gameName: null
    },
    resolvePromise: null,
    confirmModal: null,
    gameItem: null,
    userData: null,

    loadGame(game, user) {
        this.gameItem = game;
        this.userData = user;

        switch (user?.currency) {
            case 1:
                if (game.only_hkd == 1) {
                    this.getPointsConfiramtion(1);
                }
                else if (game.only_hkd == 0) {
                    this.callForGamesApi();
                }
                break;
            case 2:
                if (game.only_hkd == 1) {
                    this.getPointsConfiramtion(1);
                } else if (game.only_hkd == 0) {
                    this.getPointsConfiramtion(2);
                }
                break;
            case 3:
            case 4:
                this.callForGamesApi();
                break;
        };

    },
    getLivePockerUrl() {
        let headers = {
            'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
        }
        api.post(apiName.GET_LIVE_POCKER_URL + this.gameItem.game_code + '?dtype=m', {}, { headers }).then(response => {
            if (response) {
                if (response.status == 200) {
                    let iFrameUrl = response?.data?.data?.url;
                    if (iFrameUrl == null || iFrameUrl == undefined || iFrameUrl == '') {
                        window.store.dispatch('showErrorModal','URL not found.');
                        return;
                    };
                    this.gameData = {
                        gameUrl: iFrameUrl,
                        gameName: this.gameItem.game_name
                    };
                    this.loadIframe();
                }
            }
        }).catch(error => {
            if (error) {
                window.store.dispatch('showErrorModal', error[0]);
            }
        });
    },
    getDreamCasinoLoadGames() {
        let headers = {
            'Authorization': `Bearer ${window.cookies.get(AUTH_TOKEN)}`
        }
        window.store.dispatch('setGlobalLoader',true);
        api.post(apiName.DREAM_CASINO + this.gameItem.game_code + '/' + this.gameItem.sm_id, {}, { headers }).then(response => {
            if (response) {
                if (response.status == 200) {
                    window.store.dispatch('setGlobalLoader',false);
                    let iFrameUrl = response?.data?.data?.url;
                    if (iFrameUrl == null || iFrameUrl == undefined || iFrameUrl == '') {
                        window.store.dispatch('showErrorModal','URL not found.');
                        return;
                    };
                    this.gameData = {
                        gameUrl: iFrameUrl,
                        gameName: this.gameItem.game_name
                    };
                    this.loadIframe();
                }

            }
        }).catch(error => {
            window.store.dispatch('setGlobalLoader',false);
            if (error) {
                window.store.dispatch('showErrorModal', error[0]);
            }
        });
    },
    async getPointsConfiramtion(popUpType) {

        let confirmationPromise = new Promise((resolve) => {
            this.resolvePromise = resolve;
            this.pointsConfirmationInit(popUpType);
            this.confirmModal.show();
        });

        confirmationPromise.then(res => {
            if (res.isConfirmed) {
                this.confirmModal.hide();
                this.callForGamesApi();
            }
        });
    },
    callForGamesApi() {
        if (this.gameItem.provider == 'poker') {
            this.getLivePockerUrl()
        } else {
            this.getDreamCasinoLoadGames()
        }
    },
    createModal(modalComponent, props, modalId) {
        if (!document.getElementById(`games-modal-${modalId}`)) {

            const appInstance = document.getElementById('app');
            const modalContainer = document.createElement('div');
            modalContainer.id = `games-modal-${modalId}`
            appInstance.appendChild(modalContainer);

            let modalInstance = h(modalComponent, props)
            render(modalInstance, modalContainer);

        };

        this.confirmModal = new Modal(document.getElementById(modalId));
    },
    pointsConfirmationInit(popType) {
        const component = popType === 1 ? GamePop1 : GamePop2;
        const modalId = popType === 1 ? 'gamePop1' : 'gamePop2';
        this.createModal(component, { onAgreed: this.handleAgreed.bind(this) }, modalId);
    },
    handleAgreed() {
        if (this.resolvePromise) {
            this.resolvePromise({ isConfirmed: true });
        }
        this.confirmModal.hide();
    },
    loadIframe() {
        this.createModal(GamesIframe,
            {
                gameData: this.gameData,
                onCloseGame: this.closeGame.bind(this)
            },
            'iframeModalMain'
        );
        this.confirmModal.show();
    },
    closeGame() {

        this.confirmModal.hide();

        const modalContainer = document.getElementById('games-modal-iframeModalMain');
        if (modalContainer) {
            modalContainer.remove();
        }
        this.confirmModal = null;
    }

};

export default loadCasinoGame;