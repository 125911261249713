<template>
    <div id="gamePop1" class="modal fade casino-lubby match-reminder-modal" aria-modal="false" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-header p-0 border-0">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" style="filter: invert(1);"></button>
                </div>
                <div class="modal-body">
                    <div class="slots-casino-logo">
                        <img src="@/assets/images/slotsCasinoPopUp.webp" loading="lazy" alt="slotsCasinoPopUp">
                        <div class="gp-title">PLEASE NOTE</div>
                            <div class="gp-descr">(1 Point =  10)</div>
                            <div class="gp-welcome">Welcome to your new casino lobby</div>
                            <div class="gp-checkout">Checkout the exciting Live Casino tables, Slots and much more, upto
                                200+ game and counting...</div>
                        <button @click="$emit('agreed')" class="gp-button thm-but ok-agree-btn gradient-btn">OK, I AGREE !</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import IFrameModal from '@/modules/games/components/IFrameModal.vue';

export default {
    name: 'GamesPop1',
}
</script>