<template>

    <!-- old code -->
    <section class="sub-header-wrapper mybets-list">
        <div class="sub-header-tab my-market-tab">
            <h4 v-if="isSportsEventDetail" class="new-disp-flex">{{translatedLangData('other-markets','Other Markets')}}</h4>
            <h4 v-else >{{translatedLangData('my-market','My Market')}}</h4>
            <button class="reload-btn" @click.prevent="getMySportsServiceCall()"><i class="fas fa-redo-alt reload special-reload-class"></i> {{translatedLangData('reload','Reload')}}</button>
        </div>
        <div class="sub-header-tab-con">
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-sports-marke" role="tabpanel"
                    aria-labelledby="pills-sports-marke-tab">
                    <div class="login-modal-sec">
                        <div class="sub-header-tab-con">
                            <div class="">
                                <div class="login-modal-sec">
                                    <form>
                                        <div v-if="marketList && marketList.length != 0"
                                            class="bet-list  profit-loss-list">
                                            <table class="tbl-market">
                                                <tr v-if="isSportsEventDetail">
                                                    <th>{{translatedLangData('market-id','Market ID')}}</th>
                                                    <th>{{translatedLangData('market-name','Market Name')}}</th>
                                                    <th>{{translatedLangData('liability','Liability')}}</th>
                                                </tr>
                                                <tr v-else>
                                                    <th>{{translatedLangData('event-id','Event ID')}}</th>
                                                    <th>{{translatedLangData('event-name','Event Name')}}</th>
                                                    <th>{{translatedLangData('liability','Liability')}}</th>
                                                </tr>
                                                <tr class="market-data-tr" v-for="(item,index) in marketList" :key="index">
                                                    <td>{{ item?.event_id }}</td>
                                                    <td>
                                                        <a @click.prevent="closeFooterModal(item)" 
                                                            :data-bs-dismiss="isSportsEventDetail ? 'modal' : null" :aria-label="item.event_name">
                                                            {{ item?.event_name }}
                                                        </a>
                                                    </td>
                                                    <td class="font-red">{{ item?.exposure?.toFixed(2) }}</td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div v-else-if="marketList && marketList.length == 0 && loading">
                                            <h3 class="load-txt">{{translatedLangData('loading-market-msg','Loading Your Markets')}}</h3>
                                        </div>

                                        <div v-else-if="marketList && marketList.length == 0">
                                            <h3 class="load-txt">{{translatedLangData('no-mkt-msg','You Have No Markets Yet')}}</h3>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import api from '../services/api';
import * as apiName from '../services/urls';
import NoDataFound from '@/shared/components/no-data/NoDataFound.vue';
import { AUTH_TOKEN } from "../../../shared/constants/cookies-const";

export default {
    name: "MyMarket",
    inject:['translatedLangData'],
    components: {
        NoDataFound
    },
    mounted() {
        this.$store.dispatch('setGlobalLoader', true);
        if(this.checkIsLogin()){
            this.getMySportsServiceCall();
        }
    },
    data() {
        return {
            marketList: [],
            sportsPages: ['sports-event-detail','inplay-games','exchange-games','sports','sports-league'],
            loading: false,
        }
    },
    computed: {
        isSportsEventDetail() {
            return this.sportsPages.includes(this.$route.name);
        }
    },
    methods: {
        closeFooterModal(item){
            if(this.isSportsEventDetail){
                this.$store.dispatch('setShowMybets', true);
            }
            this.$router.push({ name: 'sports-event-detail', params: { event_id: item.event_id } });
        },
        checkIsLogin(){
            return this.$store.getters.isAuthenticated;
        },
        getMySportsServiceCall() {
            this.loading = true;
            this.marketList = [];
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.get(apiName.GET_MY_MARKETS + '/sport',{headers}).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                this.loading = false;
                this.marketList = [];
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.marketList = response.data.data;
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
}
};
</script>
<style scoped>
.reload-btn{
    background: var(--button-color);
}
.load-txt{
    color: var(--yellow-color) !important;
    text-align: center;
}
.tbl-market{
    border: 1px solid;
    width: 100%;
}
.tbl-market th{
    color: white;
    background: grey;
}
.market-data-tr{
    border: 1px solid;
}
.market-data-tr td{
    text-align: center;
    padding: 8px;
}
.font-red{
    color: red;
}
</style>