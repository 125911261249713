<template>
   <!-- Copyright -sec-section -->
   <div class="footer-copyright">
      <p>© 2022 {{domainName}}. All rights reserved. </p>
   </div>
</template>

<script>
export default {
   name: 'Copyrights',
   inject: ['translatedLangData'],
   computed: {
      siteSettings() {
         return this.$store.getters.siteSettings;
      },
      domainName() {
         return this.$store.getters.domainName;
      }
   },
}
</script>