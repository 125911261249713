<template>
    <!-- Modal -->
        <div class="modal-body">
            <div class="game-iframe-sec">
                <div class="iframe-content">
                    <video style="width: 100%;" height="240" controls preload="auto" fullscreen="show"> 
                        <source
                            :src="link"
                            type="video/mp4">
                    </video>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    name: "VideoModal",
    props: ['title', 'link'],
    mounted(){
    }
}
</script>

<style scoped>

</style>