<template>
	<div class="main">
		<div data-bs-toggle="modal" data-bs-target="#notification-alert" ref="notifyMe">
	</div>
	<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications"/>
		<!-- header section -->
	<template v-if="!underMaintenance">
		<Header v-if="checkForHeaderShow()" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" @clearSideMenu="clearSideMenu">
		</Header>
	</template>
		<div v-if="onLine">
			<!-- <NewsSection v-if="!newsSectionHide.includes($route.name)" /> -->
			<div v-if="getGlobaLoader()" class="first-loader">
				<div class="load">
					<div class="loader">
						<img :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo" alt="loader-img">
					</div>
					<h6>Welcome to {{ siteSettings?.domain_name }}</h6>
				</div>
			</div>

			<router-view :key="$route.fullPath" @error-modal="showErrorModalFunc"
				@success-modal="showSuccessModalFunc" v-if="siteSettings || underMaintenance" @updateAuthToken="refreshAuthToken"/>
		</div>

		<div v-else>
			<NoInternetConnection />
		</div>

		<!-------------Footer Section----------------->
		<template v-if="!underMaintenance">
			<footer v-if="!hideFooter.includes($route.name)" class="footer" >
				<Contacts v-if="$route.name == 'Home'" />
				<Footer ref="footerComponent" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
			</footer>
		</template>


	</div>

	<!-- Login module -->
	<Login @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></Login>

	<div :class="{ show: successMsg }" class="successfully-wrapper" style="z-index: 9999999999999999;"
		ref="successmodal" id="successfullyModal">
		<SuccessModal :message="successMsg" />
	</div>

	<div :class="{ show: errorMsg }" style="z-index: 9999999999999999999999;" class="successfully-wrapper"
		id="wrongtoaster">
		<ErrorModal :message="errorMsg" />
	</div>


	<LanguageSelection @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

	<Account v-if="checkIsLogin()" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" @handleFooterBehavior="handleFooterBehavior"/>


	<RulesModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

	<LockedBonus @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

	<Offers />

	<div ref="click_logout_msg" @click="showErrorModalFunc('You are logout from this device.')"
		style="display: hidden;">
	</div>

	<!-- <SideMenu ref="side_menu"></SideMenu> -->
</template>

<script>
import NotificationAlert from "./shared/components/modal/NotificationAlert.vue";
import Header from "@/shared/components/header/Header.vue";
import Footer from "@/shared/components/footer/Footer.vue";
import Login from "@/modules/authorization/components/Login.vue";
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import axios from 'axios';
import NoInternetConnection from "./shared/components/no-internent/NoInternetConnection.vue";
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { setHeaders } from '@/shared/services/headers';
import moment from 'moment';
import ErrorModal from "./shared/components/modal/ErrorModal.vue";
import SuccessModal from "./shared/components/modal/SuccessModal.vue";
import GamesPop1 from "./shared/components/modal/GamesPop1.vue";
import GamesPop2 from "./shared/components/modal/GamesPop2.vue";
import LanguageSelection from "./shared/components/header/LanguageSelection.vue";
import Account from "./modules/account/components/Account.vue";
import { callFavouriteApis } from '@/shared/services/common-services';
import RulesModal from "./modules/account/components/RulesModal.vue";
import LockedBonus from "./shared/components/header/LockedBonus.vue";
import { mapGetters } from 'vuex';
import socketService from "./socket/sport-socket-service";
import Contacts from "./shared/components/footer/Contacts.vue";
import Copyrights from "./shared/components/footer/Copyrights.vue";
import NewsSection from "./shared/components/modal/NewsSection.vue";
import { computed } from 'vue';
import Offers from "./shared/components/header/Offers.vue";
import { Modal } from 'bootstrap';
import LanguageService from '@/shared/services/language-service.js';

export default {
	name: "App",
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			refreshInit: false,
			scY: 0,
			scTimer: 0,
			offerModal: '',
			versionData:null,
			findActiveLang: "",
			allLanguages: null,
			langData: null,
			newsSectionHide: ['Home', 'match-market', 'wallet-history', 'wallet-history-detail', 'notifications', 'racing-list-details', 'racing-category', 'racing-carousel', 'CricketFight', 'MatchDetails', 'MyContests', 'MyContestBetDetails'],
			hideFooter:['forget-password','sign-up','MatchDetails','settings','matka-details'],
			UpdatedVersion:null,
		}
	},
	computed: {
		...mapGetters({
			captchaData: 'captchaData', siteSettings: 'siteSettings', eventDetailHideSections: 'eventDetailHideSections',domainName: 'domainName', errorMsg: 'errorMsg', successMsg: 'successMsg'
		}),
		routePath() {
			return this.$route.name
		},
		siteVersion() {
			return this.$store.getters.siteVersion;
		},
		underMaintenance() {
			return this.$store.getters.underMaintenance;
		}
	},
	mounted() {
		//for use on js pages
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		this.offerModal = new Modal("#offer_pop_up");
		window.emitLogoutMsg = this.$refs.click_logout_msg
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		//for add on all APIs
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();
		if (this.checkIsLogin()) {
			callFavouriteApis();
			if(!this.$store.getters.stateUser.notificationSubscribed && !this.$store.getters.stateUser.notificationUnsubscribed) {
				this.requestNotificationPermission()
			}
		}
		//checking internet connection
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		if (localStorage.getItem('default_class')) {
			document.documentElement.className = localStorage.getItem('default_class')
		}
		else {
			//set default theme
			this.setTheme('dark')
		}
		window.addEventListener('scroll', this.onScroll);

		// Manage Menu Modal
		this.$router.beforeEach((to, from, next) => {
			this.handleFooterBehavior(to, from); // Call method to update Footer state
			next(); // Proceed to the next route
		});

		this.showErrorModalFunc(null)
		this.showSuccessModalFunc(null)
	},
	async created() {
		await this.getSiteVersion();
		// this.getSiteSettingsServiceCall();
		this.socketAllEvents();
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},
	components: {
		Header,
		Footer,
		Login,
		NoInternetConnection,
		ErrorModal,
		SuccessModal,
		GamesPop1,
		GamesPop2,
		LanguageSelection,
		Account,
		RulesModal,
		LockedBonus,
		Contacts,
		Copyrights,
		NewsSection,
		Offers,
		NotificationAlert
		// SideMenu
	},
	provide() {
		return {
			translatedLangData: computed(() => this.translatedData)
		};
	},
	methods: {
		handleFooterBehavior(to, from) {
			const footerInstance = this.$refs.footerComponent;
			if (footerInstance) {
				footerInstance.menuTabActive = true;
				footerInstance.closeMenuModal(); // Ensure menu modal is closed on route change
			}
		},
		clearSideMenu(){
         this.$refs.side_menu.searchString='';
         this.$refs.side_menu.searchResult=null
      	},
		async getSiteVersion() {
			this.$store.dispatch('setGlobalLoader', true);
			await api.get(apiName.getVersionStatus + "?domain=" + window.location.hostname).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						let resData = response?.data?.data;
            			this.UpdatedVersion =  resData?.version;;
						this.versionData = resData;
						const storedVersion = this.siteVersion;
						if (!this.UpdatedVersion) {
              			this.UpdatedVersion = '1';
						}
						if (!storedVersion || String(storedVersion) !== String(this.UpdatedVersion) || !this.siteSettings) {
							await this.getSiteSettingsServiceCall();
						}
						else {
							this.siteSettingCommon(this.siteSettings);
							}
						const fromDateTime = new Date(resData?.maintenance?.from_date).getTime();
						const toDateTime = new Date(resData?.maintenance?.to_date).getTime();
						const currentDateTime = Date.now();
						if (resData.maintenance != null && (fromDateTime && toDateTime && fromDateTime <= currentDateTime && currentDateTime <= toDateTime)) {
							this.$store.dispatch('setUnderMaintenance', resData.maintenance);
							this.$store.dispatch('setGlobalLoader', false);
							this.$router.push({name:'Maintenance'});
							return;
						} else {
							if(this.underMaintenance) {
								this.$router.push({name:'Home'});
							}
						this.$store.dispatch('setUnderMaintenance', null);
						this.$store.dispatch('setGlobalLoader', false);
						}
						this.$store.dispatch('setGlobalLoader', false);
					}
				}
			}).catch(error => {
				if (error) {
					this.$store.dispatch('setGlobalLoader', false);
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		translatedData(key, defaultVal) {
			if (this.langData?.[key]) {
				return this.langData?.[key];
			} else {
				return defaultVal;
			}
		},
		setTheme(theme) {
			localStorage.setItem('default_class', theme)
			document.documentElement.className = theme;
		},
		getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
		async getSiteSettingsServiceCall() {
			this.$store.dispatch('setGlobalLoader', true);
			api.get(apiName.v1_SITE_SETTINGS + "?domain=" + window.location.hostname).then(async response => {
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						// let	resData = response?.data?.data;
						let resData = response?.data.data.site_settings
						let casino = response.data.data?.casino
						this.$store.dispatch('setSettings', resData);
						let casinoSections = null;
						casinoSections = casino?.sections.sort((a, b) => {
							if (a.slug === 'providers') return 1;
							if (b.slug === 'providers') return -1;
							return 0;
						});
						this.$store.dispatch('setGamesData', { sections: casinoSections });
						this.siteSettingCommon(resData);
						this.$store.dispatch('setLanguagesData', this.allLanguages);
					}
				}
			}).catch(error => {
				this.$store.dispatch('setGlobalLoader', false);
				this.$store.dispatch('setSiteVersion', null);
				if (error) {
					console.log(error);

					this.showErrorModalFunc(error[0]);
				}
			});
		},
		async siteSettingCommon(siteSetting) {
			try {
				this.allLanguages = siteSetting.language_translate_list;
				if (this.allLanguages) {
				this.findActiveLang = this.allLanguages.find(lang => lang.lang_code === siteSetting.language_code);
				const selectedLanguageUrl = localStorage.getItem('selectedLanguageUrl');
				if (!(selectedLanguageUrl === 'en' || (!selectedLanguageUrl && siteSetting.language_code === 'en'))) {
					if ((this.findActiveLang && this.findActiveLang.lang_url) || selectedLanguageUrl) {
					const langCode = selectedLanguageUrl || this.findActiveLang.lang_url;
					const aws_url_language = siteSetting.aws_url + '/' + langCode;
					this.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
					}
				}
				}
						const favElement = document.createElement('link');
						favElement.rel = 'icon';
						favElement.href = siteSetting.aws_url + siteSetting.storage_path.domain_image + siteSetting.fav_logo;
						document.head.insertAdjacentElement('beforeend', favElement);

						const title = document.getElementById("title");
						title.text = siteSetting.domain_name;

						let is_offer = localStorage.getItem("is_offer");
						if (this.checkIsLogin()) {
							this.getWalletBalance();
						}

						if (this.checkIsLogin() && siteSetting.offer != null && is_offer == null && siteSetting?.business_type == 2) {
							this.offerModal.show();
						}
						this.$store.dispatch('setSiteVersion', this.UpdatedVersion);
					} catch (error) {
						console.log("error",error);

						this.$store.dispatch('setGlobalLoader', false);
						this.$store.dispatch('setSiteVersion', null);
						this.showErrorModalFunc(error);
					}
   		 },
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}

			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);

			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);

			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
		getWalletBalance() {
			let authToken = this.$cookies.get(AUTH_TOKEN);
			if (!authToken) return;
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.exposure = response.data.ex;
					user.bets_count = response.data.bc;
					this.$store.dispatch('setUser', user);
					if ((user.exp - moment().utc().unix()) < 10) {
						this.refreshAuthToken(headers);
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		refreshAuthToken(refresh = false) {
			this.refreshInit = true;
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				if(refresh) {
					router.go();
				}
				// setTimeout(function () {
				// 	this.getWalletBalance();
				// }.bind(this), 2000);
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
				this.refreshInit = false;
			});
		},
		checkForHeaderShow() {
			if (this.routePath == 'search') {
				return false;
			} else if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},
		showErrorModalFunc(message) {
			this.$store.dispatch('showErrorModal', message)
		},
		showSuccessModalFunc(message) {
			this.$store.dispatch('showSuccessModal', message)
		},
		goToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		onScroll() {
			if (this.scTimer) return;
			this.scTimer = setTimeout(() => {
				this.scY = window.scrollY;
				clearTimeout(this.scTimer);
				this.scTimer = 0;
			}, 100);
		},
		socketAllEvents() {
			if (this.$store.getters.stateUser) {
				socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type == "myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.showSuccessModalFunc('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
					}
				});
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					} else if (data.type == 'league_ban') {
						// league ban condition
					}
				});

			}
		},
		requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}

			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			this.$refs.notifyMe.click()
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						console.log(e);
					});

					
				
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
				api.post(apiName.SUBSCRIBE_NOTIFICATION, data, { headers }).then(response => {
					this.loadingSignUp = false;
					if (response) {
						console.log(response)
					}
				}).catch(error => {
					this.loadingSignUp = false;
					if (error)
						this.showErrorModalFunc(error[0]);
				});
		},
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}
};



</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 1.5s
}

.fade-enter,
.fade-leave-to {
	opacity: 0
}

.bottom-10 {
	bottom: 10px !important;
}
</style>
